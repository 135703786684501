import React from 'react';
import styles from './index.module.sass';
//import { useTranslation } from 'react-i18next';

const TableComponent = (props) => {
  const { screenSize = 'desktop', tableData = [], tableItems = [] } = props;
  //const { t } = useTranslation('global');

  //const months = t('months', { returnObjects: true });

  const dataCellWidth = (screenSize === 'phone' && '180px') || `${100 / tableItems.length}%`;

  return (
    <div className={`${styles.tableContainer}`}>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.tableHeader}`}>
          {tableItems.map((val, key) => {
            const { title = '' } = val;
            return (
              <div className={styles.cell} style={{ width: dataCellWidth }} key={key}>
                {title}
              </div>
            );
          })}
        </div>
        {tableData.map((val, key) => {
          const columns = tableItems.map((cellVal, cellKey) => {
            const {
              field = '',
              many = false,
              isLink = false,
              title = '',
              useLength = false,
              isMoney = false,
              isArray = false,
            } = cellVal;
            let fieldName = '';
            if (many) {
              const tmpConst = field.split(',');
              for (let i = 0; i < tmpConst.length; i++) {
                if (i > 0) fieldName += '-';
                if (tmpConst[i] === 'mes') {
                  /*
                  fieldName +=
                    (tableData[key] &&
                      tableData[key][tmpConst[i]] &&
                      months[parseInt(tableData[key][tmpConst[i]]) - 1]) ||
                    '';
                    */
                } else {
                  fieldName += (tableData[key] && tableData[key][tmpConst[i]]) || '';
                }
              }
            } else if (useLength) {
              fieldName = tableData[key] && tableData[key][field] && tableData[key][field].length;
            } else if (isArray) {
              fieldName = tableData[key];
            } else {
              fieldName = (tableData[key] && tableData[key][field]) || '';
              if (isMoney) fieldName = `$${fieldName}`;
            }

            const cell = (
              <div className={styles.cell} style={{ width: dataCellWidth }} key={cellKey}>
                {(isLink && (
                  <a href={fieldName} target='_blank' rel='noopener noreferrer'>
                    {(!field && fieldName) || (fieldName && title) || ''}
                  </a>
                )) ||
                  fieldName}
              </div>
            );
            return cell;
          });
          return (
            <div className={styles.row} key={key}>
              {columns}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableComponent;
