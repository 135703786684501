import React, { useState, useEffect, useCallback } from 'react';
import styles from '../index.module.sass';

//Custom
import deptosArray from './deptos.js';
import subDeptosArray from './subdeptos.js';
import deptosArrayMX from './deptosMX.js';
import subDeptosArrayMX from './subdeptosMX.js';

//Dependencies
import superagent from 'superagent';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const deptosArr = (process.env.REACT_APP_COUNTRY === 'MX' && deptosArrayMX) || deptosArray;
const subDeptosArr = (process.env.REACT_APP_COUNTRY === 'MX' && subDeptosArrayMX) || subDeptosArray;

const initObject = {
  aplicacion: '',
  carrusel: '',
  clase: '',
  clave: '',
  departamento: '',
  descripcion: '',
  familiaOlfativa: '',
  id: '0',
  imagen: '',
  ingredientes: '',
  link: '',
  marca: '',
  perfumista: '',
  precio: '',
  producto: '',
  subdepartamento: '',
};

const ProductForm = (props) => {
  const { match, webserviceURL, history } = props;
  const [flag, setFlag] = useState(false);
  const [eventForm, setEventForm] = useState(initObject);

  function onEditorChange(e, name) {
    setEventForm((prevState) => {
      return {
        ...prevState,
        [name]: e,
      };
    });
  }

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === 'carrusel') value = value.toUpperCase();

    setEventForm({
      ...eventForm,
      [e.target.name]: value,
    });
  };

  const onSubmit = () => {
    let jsonBody = Object.assign({}, eventForm);
    const action =
      (match.params && match.params.productId !== 'newproduct' && 'ACTUALIZAR') || 'AGREGAR';
    jsonBody.accion = action;

    const url = `${webserviceURL}/postDescripciones`;

    if (jsonBody.clave.trim() === '') {
      alert('Falta colocar la clave del producto');
      return;
    }

    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .set('token', localStorage.getItem('TMBtoken'))
      .send(jsonBody)
      .then((res) => {
        if (res.body) {
          const { mensaje, tipoMensaje } = res.body;
          window.scrollTo(0, 0);
          alert(mensaje);
          if (tipoMensaje === 'exito') {
            if (action === 'AGREGAR') setEventForm(initObject);
          }
        } else {
          alert('Ocurró un error, vuelva a intentarlo');
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Ocurró un error');
      });
  };

  const getProduct = useCallback(() => {
    window.scrollTo(0, 0);
    if (match.params && match.params.productId !== 'newproduct') {
      if (flag) return;
      setFlag(true);
      const url = `${webserviceURL}/getDescripciones?depto=0&subdepto=&clave=${match.params.productId}`;
      superagent
        .get(url)
        .set('Content-Type', 'application/json')
        .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
        .set('token', localStorage.getItem('TMBtoken'))
        .then((res) => {
          if (res.body && res.body.length > 0) {
            setEventForm(res.body[0]);
          } else {
            alert('ocurrió un error');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (match.params && match.params.productId === 'newproduct') {
      setEventForm(initObject);
    }
  }, [match, webserviceURL, flag]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <div className={styles.productFormContainer}>
      <div>
        <div>
          <span className={styles.link} onClick={() => history.goBack()}>
            Regresar
          </span>
        </div>
      </div>
      {(match.params && match.params.productId === 'newproduct' && (
        <div className={styles.inputsContainer}>
          <div className={`${styles.three} ${styles.first}`}>
            <TextField
              onChange={handleChange}
              name='clave'
              placeholder='Clave'
              type='text'
              value={eventForm.clave}
              variant='outlined'
              fullWidth
            />
          </div>
        </div>
      )) || <div className={styles.attributeName}>Clave: {eventForm.clave}</div>}
      <div className={styles.inputsContainer}>
        <div className={`${styles.three} ${styles.first}`}>
          <TextField
            onChange={handleChange}
            name='producto'
            placeholder='Nombre'
            type='text'
            value={eventForm.producto}
            variant='outlined'
            fullWidth
          />
        </div>
        <div className={styles.three}>
          <TextField
            onChange={handleChange}
            name='clase'
            placeholder='Nombre de imagen (sin extensión)'
            type='text'
            value={eventForm.clase}
            inputProps={{ maxLength: 20 }}
            variant='outlined'
            fullWidth
          />
        </div>
        <div className={styles.three}>
          <TextField
            onChange={handleChange}
            name='carrusel'
            placeholder='Mostrar en carrousel (S = Sí , X = No)'
            type='text'
            value={eventForm.carrusel}
            inputProps={{ maxLength: 1 }}
            variant='outlined'
            fullWidth
          />
        </div>
      </div>

      <div className={styles.inputsContainer}>
        <div className={`${styles.three} ${styles.first}`}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Departamentos</InputLabel>
            <Select
              label='Departamento'
              value={eventForm?.departamento || '1'}
              onChange={handleChange}
              name='departamento'>
              {deptosArr.map((option) => (
                <MenuItem key={option.value} value={option?.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={`${styles.three}`}>
          <FormControl variant='outlined' fullWidth>
            <InputLabel>Sub departamentos</InputLabel>
            <Select
              label='Sub departamento'
              value={eventForm?.subdepartamento || ''}
              onChange={handleChange}
              name='subdepartamento'>
              {(
                (parseInt(eventForm.departamento) - 1 >= 0 &&
                  subDeptosArr[parseInt(eventForm.departamento) - 1]) ||
                []
              ).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={`${styles.three}`}>
          <TextField
            onChange={handleChange}
            name='precio'
            placeholder='Precio (solo números)'
            type='text'
            value={eventForm.precio}
            variant='outlined'
            fullWidth
          />
        </div>
      </div>
      <div className={styles.attributeName}>Descripción</div>
      <div>
        <ReactQuill
          theme='snow'
          value={eventForm.descripcion}
          onChange={(e) => onEditorChange(e, 'descripcion')}
        />
      </div>

      <div>
        <div className={styles.attributeName}>Ingredientes</div>
        <ReactQuill
          theme='snow'
          value={eventForm.ingredientes}
          onChange={(e) => onEditorChange(e, 'ingredientes')}
        />
      </div>

      <div>
        <div className={styles.attributeName}>Aplicación</div>
        <ReactQuill
          theme='snow'
          value={eventForm.aplicacion}
          onChange={(e) => onEditorChange(e, 'aplicacion')}
        />
      </div>

      <div className={styles.buttonsContainer}>
        <Button
          type='button'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => {
            //if (!buttonDisabled) onSubmit(e);
            onSubmit(e);
          }}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default ProductForm;
