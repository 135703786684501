import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

//Components
import DownloadSiVale from '../DownloadSiVale';
import DeleteSiVale from '../DeleteSiVale';

//Styles
import styles from './index.module.sass';

export default function MenuSiVale(props) {
  const { webserviceURL, setOptionSelected, setSectionName, permissions } = props;
  const [valueMenu, setValueMenu] = useState(0);

  const handleChange = (event, newValue) => {
    setValueMenu(newValue);
  };

  return (
    <>
      <div className={styles.container}>
        <Box sx={{ width: '100%' }}>
          <Tabs value={valueMenu} onChange={handleChange}>
            <Tab label='Dar de baja' className={styles.tab} />
            <Tab label='Descargar' className={styles.tab} />
          </Tabs>
        </Box>
      </div>

      {valueMenu === 0 && (
        <div className={styles.content}>
          {permissions.SiVale === '2' ? (
            <DeleteSiVale
              {...props}
              setSectionName={setSectionName}
              setOptionSelected={setOptionSelected}
              webserviceURL={webserviceURL}
            />
          ) : (
            <div className={styles.permisos}>No tienes permiso para ver esta opción</div>
          )}
        </div>
      )}
      {valueMenu === 1 && (
        <div className={styles.content}>
          <DownloadSiVale
            {...props}
            setSectionName={setSectionName}
            setOptionSelected={setOptionSelected}
            webserviceURL={webserviceURL}
          />
        </div>
      )}
    </>
  );
}
