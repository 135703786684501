import React, { useState, useCallback, useEffect } from 'react';
import superagent from 'superagent';

import InputFly from '../../../misc/InputFly';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from './TableRow';

import styles from './index.module.sass';

const nowDate = new Date();

const primerDesde = `${nowDate.getFullYear()}${('0' + (nowDate.getMonth() + 1)).slice(-2)}01`;
const primerHasta = `${nowDate.getFullYear()}${('0' + (nowDate.getMonth() + 1)).slice(-2)}${(
  '0' + nowDate.getDate()
).slice(-2)}`;

export default function General({ webserviceURL, device }) {
  const { screenSize } = device;
  const [loading, setLoading] = useState(false);
  const [digitalOrders, setDigitalOrders] = useState([]);
  const [rowSelected, setRowSelected] = useState(null);
  const [serchData, setSearchData] = useState({
    desde: primerDesde,
    hasta: primerHasta,
    estatus: 'Todos',
    kit: '',
  });
  const [totals, setTotals] = useState({
    visitas: 0,
    pendiente: 0,
    solicitado: 0,
    aceptado: 0,
    pagado: 0,
  });

  const handleChange = useCallback((e) => {
    setSearchData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const getReporteConcentradoCatalogoDigital = useCallback(
    async (searchObject) => {
      setLoading(true);
      const url = `${webserviceURL}/getReporteConcentradoCatalogoDigital?fechaInicio=${searchObject.desde.replace(
        /-/g,
        '',
      )}&fechaFin=${searchObject.hasta.replace(/-/g, '')}&kit=${searchObject.kit}&idioma=1`;

      await superagent
        .get(url)
        .set('Content-Type', 'application/json')
        .set('token', localStorage.getItem('TMBtoken'))
        .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
        .then((res) => {
          const detalle = res?.body?.detalle.reverse() || [];
          setDigitalOrders(detalle);
          let visitas = 0;
          let pendiente = 0;
          let solicitado = 0;
          let aceptado = 0;
          let pagado = 0;

          console.log(detalle.length);

          for (let i = 0; detalle.length > i; i++) {
            visitas += detalle[i].visitas;
            pendiente += detalle[i].pendiente;
            solicitado += detalle[i].solicitado;
            aceptado += detalle[i].aceptado;
            pagado += detalle[i].pagado;
          }

          setTotals({
            visitas,
            pendiente,
            solicitado,
            aceptado,
            pagado,
          });

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert('An error occurred');
        });
    },
    [webserviceURL],
  );

  useEffect(() => {
    getReporteConcentradoCatalogoDigital({
      desde: primerDesde,
      hasta: primerHasta,
      kit: '',
    });
  }, [getReporteConcentradoCatalogoDigital]);

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.searchContainer}>
        <div className={styles.inputContainer}>
          <InputFly
            handleChange={handleChange}
            name='desde'
            placeholder='De la fecha'
            type='date'
            shrink={true}
            value={serchData.desde}
          />
        </div>
        <div className={styles.inputContainer}>
          <InputFly
            handleChange={handleChange}
            name='hasta'
            placeholder='A la fecha'
            type='date'
            shrink={true}
            value={serchData.hasta}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              getReporteConcentradoCatalogoDigital(serchData);
            }}
            color='primary'
            variant='contained'
            size='large'
            className={styles.button}>
            {loading ? <CircularProgress className={styles.circularProgress} /> : 'Buscar'}
          </Button>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div
          className={styles.table}
          style={{
            width: (screenSize === 'phone' && `${120 * 6}px`) || 'auto',
          }}>
          <TableRow
            order={{
              fecha: 'Fecha (aaa-mm-dd)',
              visitas: 'Sesiones',
              pendiente: 'Pendiente',
              solicitado: 'Solicitado',
              aceptado: 'Aceptado',
              pagado: 'Pagado',
            }}
            screenSize={screenSize}
            isHeader={true}
            totals={totals}
          />
          {digitalOrders.map((order, key) => {
            return (
              <div onClick={() => setRowSelected(key)} key={key}>
                <TableRow
                  order={order}
                  screenSize={screenSize}
                  rowSelected={rowSelected}
                  rowNumber={key}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
