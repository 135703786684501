import React, { useState, useEffect } from 'react';
import styles from './index.module.sass';

//Components
import Button from '@material-ui/core/Button';
import TableComponent from '../TableComponent';
import CircularProgress from '@material-ui/core/CircularProgress';

//Dependencies
import superagent from 'superagent';

const DHL = (props) => {
  const { device, setSectionName, setOptionSelected, webserviceURL2 } = props;
  const { screenSize = 'desktop' } = device;
  const [pdfURL, setPdfURL] = useState('');
  const [displayedOrders, setDisplayedOrders] = useState('');
  const [pdfRecord, setPdfRecord] = useState([]);
  const [guidesPending, setGuidesPending] = useState('');
  const [orders, setOrders] = useState([]);
  const [showOption, setShowOption] = useState('ORDERS');
  const [actionType, setActionType] = useState('CONSULTAR');
  const [loading, setLoading] = useState(false);

  const ordersHeader = [
    {
      title: 'Kit',
      field: 'kit',
    },
    {
      title: 'Pedido',
      field: 'pedido',
    },
    {
      title: 'Guía',
      field: 'guia',
    },
  ];

  const recordHeader = [
    {
      title: 'Link',
      isArray: true,
      isLink: true,
    },
  ];

  const getGuides = (action) => {
    setLoading(true);
    setActionType(action);
    setOrders([]);
    setPdfURL('');
    setPdfRecord([]);
    setGuidesPending('');
    setShowOption('ORDERS');

    if (action === 'GENERAR') {
      const option = window.confirm('¿Quieres generar las guías?');
      if (!option) {
        setLoading(false);
        return;
      }
    }

    const url = `${webserviceURL2}/generarGuiaDhl?action=${action}`;
    superagent
      .get(url)
      .then((res) => {
        const { body = {} } = res;
        const {
          message = 'error',
          orderList = [],
          pathToPDF = '',
          pdfHistory = [],
          totalGuidesPending = '',
          typeMessage = 'error',
          guidesToGenerate = '',
        } = body;

        if (typeMessage !== 'success') {
          alert(message);
        }

        setDisplayedOrders(guidesToGenerate);
        setPdfURL(pathToPDF);
        setPdfRecord(pdfHistory.reverse());
        setGuidesPending(totalGuidesPending);
        setOrders(orderList);
        setLoading(false);
      })
      .catch((err) => {
        alert('ERROR EN GUIAS DE DHL');
        console.log(err, 'ERROR EN GUIAS DE DHL');
        setLoading(false);
      });
  };

  useEffect(() => {
    setSectionName('DHL');
    setOptionSelected('dhl');
  }, [setSectionName, setOptionSelected]);

  return (
    <div id={styles.Estafeta} className={styles[screenSize]}>
      <div className={styles.title}>Consulta y generación de guías</div>
      <div className={styles.container}>
        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => getGuides('CONSULTAR')}>
          CONSULTAR
        </Button>
        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button}`}
          onClick={(e) => getGuides('GENERAR')}>
          GENERAR
        </Button>
        {loading && <CircularProgress />}
        <div>
          {displayedOrders && (
            <div className={styles.text}>
              <span>Pedidos mostrados:</span> {displayedOrders}
            </div>
          )}
          {guidesPending && (
            <div className={styles.text}>
              <span>Guías pendientes:</span> {guidesPending}
            </div>
          )}
          {pdfURL && (
            <div className={styles.linkContainer}>
              <a className={styles.link} href={pdfURL} target='_blank' rel='noopener noreferrer'>
                Descargar PDF con las guías generadass
              </a>
            </div>
          )}
          {actionType === 'CONSULTAR' && (
            <div className={styles.linkContainer}>
              {orders.length > 0 && (
                <span className={styles.link} onClick={() => setShowOption('ORDERS')}>
                  Pedidos
                </span>
              )}
              {pdfRecord.length > 0 && (
                <span className={styles.link} onClick={() => setShowOption('RECORDS')}>
                  Historial de txt
                </span>
              )}
            </div>
          )}
          {orders.length > 0 && (showOption === 'ORDERS' || actionType === 'GENERAR') && (
            <TableComponent screenSize={screenSize} tableData={orders} tableItems={ordersHeader} />
          )}
          {pdfRecord.length > 0 && showOption === 'RECORDS' && actionType === 'CONSULTAR' && (
            <TableComponent
              screenSize={screenSize}
              tableData={pdfRecord}
              tableItems={recordHeader}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DHL;
