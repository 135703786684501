import React, { memo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import styles from './index.module.sass';

function shouldComponentUpdate(prevMovie, nextMovie) {
  return (
    prevMovie.value === nextMovie.value &&
    prevMovie.name === nextMovie.name &&
    prevMovie.options === nextMovie.options
  );
}

const SelectType = (props) => {
  const { handleChange, options, value, name, placeholder, helperText, error, required } = props;

  return (
    <TextField
      id='outlined-select-currency'
      select
      label={placeholder}
      className={styles.textfield}
      value={value}
      onChange={handleChange}
      required={required}
      SelectProps={{
        MenuProps: {
          className: styles.menu,
        },
      }}
      helperText={helperText}
      margin='normal'
      variant='outlined'
      name={name}
      error={error}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default memo(SelectType, shouldComponentUpdate);
