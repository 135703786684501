import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import styles from './index.module.sass';

export default function SkeletonCmp() {
  return (
    <Box>
      <Skeleton variant='rectangular' animation='wave' className={styles.loadingText} />
      <Skeleton variant='rectangular' animation='wave' className={styles.loadingText} />
      <Skeleton variant='rectangular' animation='wave' className={styles.loadingText} />
      <Skeleton variant='rectangular' animation='wave' className={styles.loadingText} />
    </Box>
  );
}
