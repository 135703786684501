import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import NewNotifications from './NewNotification';
import NotificationsSent from './NotificationsSent';

import styles from './index.module.sass';

export default function Notifications({ device, history, webserviceURL, getCookie, token, match }) {
  return (
    <div className={styles.container}>
      <div className={styles.secondaryMenu}>
        <Link to={`${match.path}/`} className={styles.secondaryLink}>
          Crear Notificación
        </Link>
        <Link to={`${match.path}/enviadas`} className={styles.secondaryLink}>
          Notificaciones enviadas
        </Link>
      </div>
      <Switch>
        <Route
          exact
          path={`${match.path}/`}
          render={(props) => {
            return (
              <NewNotifications
                {...props}
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />
            );
          }}
        />
        <Route
          path={`${match.path}/enviadas`}
          render={(props) => {
            return (
              <NotificationsSent
                {...props}
                device={device}
                history={history}
                webserviceURL={webserviceURL}
                getCookie={getCookie}
                token={token}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
}
