const EN = {
  //Sign
  langSign: {
    title: 'Login',
    employeeInput: 'Kit number *',
    passInput: 'Password *',
    buttonText: 'Login',
    incorrectData: 'Incorrect data',
    permissionDenied: 'You do not have permission to enter this section',
    incorrectInfo: 'Incorrect info',
    altLogo: 'Terramar Brands log',
  },
  langMenu: {
    atentions: 'Atentions',
    indicators: 'Indicadores',
    products: 'Web products',
    estafeta: 'Estafeta',
    dhl: 'DHL',
    dhlAvant: 'DHL Avant',
    paquete: 'Paquete express',
    rop: 'Form ROP',
    surveyadmin: 'Clima organizacional',
    survey: 'Encuesta clima organizacional',
    terrapedia: 'Terrapedia',
    logout: 'Logout',
    loadSheet: 'Loadsheet',
    digitalcatalog: 'Catálogo Digital',
    users: 'Habilitar usuarios',
    notifications: 'Notificationes',
    card: 'Tarjetas Si Vale',
    db2: 'DB2 web query',
  },
  langAtentions: {
    sectionName: 'Atentions',
    wrongBrowser: 'Your browser does not allow this action',
    id: 'Invoice',
    statusCaptured: 'Captured',
    statusInProcess: 'In Process',
    statusReplica: 'Replica',
    statusClosed: 'Closed',
  },
};
export default EN;
