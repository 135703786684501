import React, { useEffect, useState, useCallback } from 'react';
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './index.module.sass';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pedidos from './Pedidos.js';
import Ordenando from './Ordenando.js';
import QNCS from './QNCS.js';
import Kois from './Kois.js';
// import NoStockProducts from './NoStockProducts';
import MonitorPagos from './monitorPagos.js';

// const location = process.env.REACT_APP_COUNTRY;

const Indicators = (props) => {
  const { setSectionName, device, setOptionSelected, webserviceURL, token } = props;

  const [value, setValue] = useState(0);
  const [indicatorArray, setIndicatorArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  // const [inventory, setInventory] = useState([]);

  const requestIndicator = useCallback(
    (option) => {
      setLoading(true);
      const url = `${webserviceURL}/getAdminIndicadores?opcion=${option}`;
      superagent
        .get(url)
        .set('token', token)
        .auth(token, { type: 'bearer' })
        .then((res) => {
          const data = res.body;
          if (data) setIndicatorArray(data);
          setRowSelected(null);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, 'ERROR EN INDICADORES');
          setLoading(false);
        });
    },
    [webserviceURL, token],
  );

  // function getInventario() {
  //   setLoading(true);
  //   const url = `${webserviceURL}/GetInventario?idioma=1`;
  //   superagent
  //     .get(url)
  //     .set('token', token)
  //     .auth(token, { type: 'bearer' })
  //     .then((res) => {
  //       const data = res?.body || {};
  //       const { listaInventario = [] } = data;
  //       setInventory(listaInventario);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err, 'ERROR EN INVENTARIO INDICADORES');
  //       setLoading(false);
  //     });
  // }

  useEffect(() => {
    setSectionName('Indicadores');
    setOptionSelected('indicators');
    requestIndicator(1);
  }, [requestIndicator, setOptionSelected, setSectionName]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue <= 3) requestIndicator(newValue + 1);
    // if (newValue === 4) getInventario();
  };

  return (
    <div id={styles.Indicators}>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          className={styles.tabs}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'>
          <Tab label='PEDIDOS' className={styles.tab} />
          <Tab label='ORDENANDO' className={styles.tab} />
          <Tab label='QNCS' className={styles.tab} />
          <Tab label='KOIS' className={styles.tab} />
          {/* {location === 'US' && <Tab label='PRODUCTOS' />} */}
          <Tab label='MONITOR DE PAGOS WEB' className={styles.tab} />
        </Tabs>
      </AppBar>
      {(loading && (
        <div className={styles.loadingContainer}>
          <CircularProgress className={styles.circularProgress} />
        </div>
      )) || (
        <div className={styles.tableContainer}>
          {value === 0 && (
            <Pedidos
              device={device}
              indicatorArray={indicatorArray}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          )}
          {value === 1 && (
            <Ordenando
              device={device}
              indicatorArray={indicatorArray}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          )}
          {value === 2 && (
            <QNCS
              device={device}
              indicatorArray={indicatorArray}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          )}
          {value === 3 && (
            <Kois
              device={device}
              indicatorArray={indicatorArray}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          )}
          {/* {value === 4 && (
            <NoStockProducts
              device={device}
              inventory={inventory}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
            />
          )} */}
          {value === 4 && (
            <MonitorPagos
              device={device}
              rowSelected={rowSelected}
              setRowSelected={setRowSelected}
              webserviceURL={webserviceURL}
              token={token}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Indicators;
