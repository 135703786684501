import React from 'react';

import styles from './index.module.sass';

export default function TableRow({
  screenSize,
  order = [],
  isHeader = false,
  rowNumber,
  rowSelected,
  totals,
}) {
  return (
    <div
      className={`${styles.tableRow} ${styles[screenSize]} ${
        (isHeader && styles.isHeader) || null
      } ${styles[rowNumber === rowSelected && !isHeader]}`}>
      <div className={styles.rowItem}>{order?.fecha}</div>
      <div className={styles.rowItem}>
        <div>{order?.visitas}</div>
        {totals && <div>({totals.visitas})</div>}
      </div>
      <div className={styles.rowItem}>
        <div>{order?.pendiente}</div>
        {totals && <div>({totals.pendiente})</div>}
      </div>
      <div className={styles.rowItem}>
        <div>{order?.solicitado}</div>
        {totals && <div>({totals.solicitado})</div>}
      </div>
      <div className={styles.rowItem}>
        <div>{order?.aceptado}</div>
        {totals && <div>({totals.aceptado})</div>}
      </div>
      <div className={styles.rowItem}>
        <div>{order?.pagado}</div>
        {totals && <div>({totals.pagado})</div>}
      </div>
    </div>
  );
}
