const subdeptos = [
  [
    //Color
    {
      value: '1',
      label: 'Rostro',
    },
    {
      value: '2',
      label: 'Ojos',
    },
    {
      value: '3',
      label: 'Labios',
    },
    {
      value: '4',
      label: 'Uñas',
    },
  ],
  [
    //Fragancias
    {
      value: '1',
      label: 'Él',
    },
    {
      value: '2',
      label: 'Ella',
    },
    {
      value: '3',
      label: 'Bebé',
    },
    {
      value: '4',
      label: 'Niña',
    },
  ],
  [
    //Cuidado capilar
    {
      value: '1',
      label: 'Cuidado capilar',
    },
  ],
  [
    //Cuidado de la barba
    {
      value: '1',
      label: 'Cuidado de la barba',
    },
  ],
  [
    //Cuidado de la piel
    {
      value: '1',
      label: 'Limpieza',
    },
    {
      value: '2',
      label: 'Hidratación',
    },
    {
      value: '3',
      label: 'Ojos',
    },
    {
      value: '4',
      label: 'Especiales Anti-Edad',
    },
    {
      value: '5',
      label: ' Control de Grasa',
    },
    {
      value: '6',
      label: 'Manchas',
    },
    {
      value: '7',
      label: 'Proteccion Solar',
    },
  ],
  [
    //Maquillaje
    {
      value: '1',
      label: 'Especiales Anti-edad',
    },
    {
      value: '2',
      label: 'Control de grasa',
      labelEng: 'Eyes',
    },
    {
      value: '3',
      label: 'Manchas',
      labelEng: 'Lips',
    },
    {
      value: '4',
      label: '',
      labelEng: 'Nail',
    },
    {
      value: '5',
      label: 'Cuerpo',
    },
  ],
  [
    //Fragrances
    {
      value: '1',
      label: 'Protección solar',
    },
    {
      value: '2',
      label: 'Cuidado capilar',
    },
  ],
  [
    //Cuerpo
    {
      value: '1',
      label: 'Cuerpo',
    },
  ],
  [
    //Antitranspirantes
    //CC Cream
    {
      value: '1',
      label: 'Ella',
    },
    {
      value: '2',
      label: 'Él',
    },
  ],
  [
    //Higiene intima
    {
      value: '1',
      label: 'Regular',
    },
    {
      value: '2',
      label: '50 años o más',
    },
    {
      value: '3',
      label: 'Período femenino',
    },
    {
      value: '4',
      label: 'Masculina',
    },
  ],
  [
    //Sanitizantes
    {
      value: '1',
      label: 'Sanitizantes',
    },
  ],
];

export default subdeptos;
