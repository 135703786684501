import React from 'react';
import styles from './index.module.sass';

const TableRow = (props) => {
  const { screenSize, val, setRowSelected, setOpen, utf8Decode } = props;
  let splitDate = val.fechaCaptura.split('-');
  let year = splitDate[0];
  let month = splitDate[1];
  let day = splitDate[2];

  let status = '';
  if (val.status === '10') status = 'Capturada';
  if (val.status === '20') status = 'En seguimiento';
  if (val.status === '40') status = 'Réplica';
  if (val.status === '80') status = 'Cerrada';

  let fechacierre = '';
  if (val.fechaCierre) {
    let closeSplitDate = val.fechaCierre.split('-');
    const closeyear = closeSplitDate[0];
    const closemonth = closeSplitDate[1];
    const closeday = closeSplitDate[2];

    fechacierre = closeday + '/' + closemonth + '/' + closeyear;
  }

  const HandleClick = (e, val) => {
    setRowSelected(val);
    setOpen(true);
  };

  return (
    <div
      className={`${styles.tableRow} ${styles[screenSize]}`}
      onClick={(e) => HandleClick(e, val)}>
      <div className={styles.rowItem}>{val.folio}</div>
      <div className={styles.rowItem}>{utf8Decode(val.areaDescri)}</div>
      {screenSize !== 'phone' && (
        <div className={styles.rowItem}>{utf8Decode(val.deptoDescri)}</div>
      )}
      <div className={styles.rowItem}>{`${day}/${month}/${year}`}</div>
      {screenSize !== 'phone' && <div className={styles.rowItem}>{status}</div>}
      {screenSize !== 'phone' && <div className={styles.rowItem}>{val.kit}</div>}
      {screenSize !== 'phone' && <div className={styles.rowItem}>{val.kitAfectado}</div>}
      {screenSize !== 'phone' && (
        <div className={styles.rowItem}>{utf8Decode(val.kitSeguimiento)}</div>
      )}
      {screenSize !== 'phone' && <div className={styles.rowItem}>{fechacierre}</div>}
    </div>
  );
};

export default TableRow;
