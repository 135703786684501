import React, { useState, useEffect, useCallback } from 'react';

// components
import Button from '@material-ui/core/Button';
import all from './questions.json';
import getCookie from '../Queries/getCookie';
import postsurvey from '../Queries/postsurvey';
import Alert from '../Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import styles from './index.module.sass';

export default function Questions({ history, screenSize }) {
  const { questions } = all;
  const [questionActually, setquestionActually] = useState(0);
  const [answer, setanswer] = useState(questions[questionActually]);
  const [selectValor, setselectValor] = useState('');
  const [respExtra, setExtra] = useState('');
  const [option, setoption] = useState('');
  const [option2, setoption2] = useState('');
  const [option3, setoption3] = useState('');
  const [survey, setSurvey] = useState([]);
  const [message, setmessage] = useState('');
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [questionPosition, setQuestionPosition] = useState(0);
  const [loading, setloading] = useState(true);

  function handleChange(e, type) {
    switch (type) {
      case 'extra':
        setExtra(e.target.value);
        break;
      case 'openAnswer':
        setselectValor(e.target.value);
        break;
      case 'option':
        setoption(e.target.value);
        break;
      case 'option2':
        setoption2(e.target.value);
        break;
      case 'option3':
        setoption3(e.target.value);
        break;
      default:
        break;
    }
  }

  function movingQuestion(letter, num, type, extra, position, subquestion, valor) {
    const tmpArray = [];
    switch (type) {
      case 'options':
      case 'openAnswer':
        if (selectValor === '') {
          setAlertText('No puedes avanzar si no respondes la pregunta');
          setOpen(true);
          return;
        } else {
          if (extra === true) {
            if (respExtra === '') {
              setAlertText('No puedes avanzar si no completas la respuesta');
              setOpen(true);
              return;
            } else {
              setquestionActually(questionActually + 1);
              setanswer(questions[questionActually + 1]);
            }
          } else if (subquestion === true) {
            switch (valor) {
              case 'Si':
                if (option === '' || option2 === '' || option3 === '') {
                  setAlertText('No puedes avanzar si no respondes las 3 opciones');
                  setOpen(true);
                  return;
                } else {
                  setquestionActually(questionActually + 1);
                  setanswer(questions[questionActually + 1]);
                }
                break;

              case 'No':
                if (respExtra === '') {
                  setAlertText('No puedes avanzar si no completas la respuesta');
                  setOpen(true);
                  return;
                } else {
                  setquestionActually(questionActually + 1);
                  setanswer(questions[questionActually + 1]);
                }
                break;
              default:
                setAlertText('Ocurrio un error');
                setOpen(true);
                break;
            }
          } else {
            setquestionActually(questionActually + 1);
            setanswer(questions[questionActually + 1]);
          }
        }
        break;

      case 'threeOptions':
        if (option === '' || option2 === '' || option3 === '') {
          setAlertText('No puedes avanzar si no respondes las 3 opciones');
          setOpen(true);
          return;
        } else {
          setquestionActually(questionActually + 1);
          setanswer(questions[questionActually + 1]);
        }
        break;

      default:
        setAlertText('Ocurrio un error');
        setOpen(true);
        break;
    }

    let json = {
      QuestionNumber: `${letter}${num}`,
      Reply: selectValor,
      Observation1: option || respExtra,
      Observation2: option2,
      Observation3: option3,
    };

    tmpArray.push(json);
    setSurvey((tmpArray) => [...tmpArray, json]);
    setExtra('');
    setselectValor('');
    setoption('');
    setoption2('');
    setoption3('');

    setQuestionPosition(position);
  }

  function removeQuestion(position) {
    var remove = position - 1;
    survey.pop();
    setQuestionPosition(remove);
    setquestionActually(questionActually - 1);
    setanswer(questions[questionActually - 1]);
  }

  const sendSurvey = useCallback(async () => {
    setloading(true);
    const json = {
      Action: 'ALTA',
      Name: getCookie('kitName'),
      Location: getCookie('Location'),
      Department: getCookie('Department'),
      Replies: survey,
    };

    const response = await postsurvey({ json });
    if (response.TypeMessage === 'success') {
      setmessage('¡Gracias por responder!');
      document.cookie = 'usuario=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'usuarioOk=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'Location=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'Department=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else {
      setmessage(response.Message);
      setAlertText(response.Message);
      setOpen(true);
    }
    setloading(false);
  }, [survey]);

  useEffect(() => {
    if (getCookie('st') === null) {
      history.push(`/`);
    } else {
      if (questionPosition === questions.length) {
        sendSurvey();
      }
    }
  }, [questionPosition, sendSurvey, questions, history]);

  return (
    <div>
      <Alert open={open} setOpen={setOpen} title={'Terramar'} text={alertText} />
      <div className={styles.closeSesion}>&nbsp;</div>
      <div className={styles.info}>
        <label className={styles.label}>Centro:</label> {getCookie('Location')}
      </div>
      <div className={styles.info}>
        <label className={styles.label}>Área:</label> {getCookie('Department')}
      </div>
      {questionActually < questions.length ? (
        <>
          <div className={styles.group}>{answer.section}</div>
          <div className={styles.container}>
            <div className={styles.question}>
              {answer.letter}
              {answer.num} .- {answer.question}
            </div>

            {answer.type === 'options' && (
              <>
                <div
                  className={`${answer.res.length === 2 ? styles.twoOptions : styles.options} ${
                    styles[screenSize]
                  }`}>
                  {answer.res.map((value, index) => (
                    <div className={`${styles[screenSize]} ${styles.option}`} key={index}>
                      {selectValor === value.value ? (
                        <div>
                          <i className='material-icons'>radio_button_checked</i>
                        </div>
                      ) : (
                        <div>
                          <i
                            className='material-icons'
                            onClick={(e) => setselectValor(value.value)}>
                            radio_button_unchecked
                          </i>
                        </div>
                      )}
                      <label>{value.label}</label>
                    </div>
                  ))}
                </div>
                {/* cuando tiene una sub pregunta, dependiendo de la respuesta */}
                {answer.subquestion === true && (
                  <>
                    {selectValor === 'Si' && (
                      <div>
                        <div>
                          <label className={styles.question}>{answer.textSubquestion}</label>
                        </div>
                        <div className={styles.optionsInputs}>
                          <div className={styles.separator}>
                            <input
                              type='text'
                              className={styles.inputText}
                              placeholder='Opción 1'
                              name='option'
                              value={option}
                              maxLength={250}
                              onChange={(e) => handleChange(e, 'option')}
                            />
                          </div>
                          <div className={styles.separator}>
                            <input
                              type='text'
                              className={styles.inputText}
                              placeholder='Opción 2'
                              name='option2'
                              value={option2}
                              maxLength={250}
                              onChange={(e) => handleChange(e, 'option2')}
                            />
                          </div>
                          <div className={styles.separator}>
                            <input
                              type='text'
                              className={styles.inputText}
                              placeholder='Opción 3'
                              name='option3'
                              maxLength={250}
                              value={option3}
                              onChange={(e) => handleChange(e, 'option3')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {selectValor === 'No' && (
                      <div>
                        <div>
                          <label className={styles.question}>{answer.labelSubPre}</label>
                        </div>

                        <div className={styles.options}>
                          <textarea
                            rows={10}
                            cols={100}
                            maxLength={250}
                            className={styles.textArea}
                            name='selectValor'
                            value={respExtra}
                            onChange={(e) => handleChange(e, 'extra')}></textarea>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {answer.extra === true && (
                  <div>
                    <div>
                      <label className={styles.question}>{answer.label}</label>
                    </div>
                    <div className={styles.area}>
                      <div className={styles.comment}>
                        <textarea
                          className={styles.textArea}
                          name='extra'
                          maxLength={250}
                          value={respExtra}
                          onChange={(e) => handleChange(e, 'extra')}></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {answer.type === 'openAnswer' && (
              <>
                <div className={styles.area}>
                  <div className={styles.comment}>
                    <textarea
                      className={styles.textArea}
                      name='selectValor'
                      maxLength={50}
                      value={selectValor}
                      onChange={(e) => handleChange(e, 'openAnswer')}></textarea>
                  </div>
                </div>

                {answer.extra === true && (
                  <div>
                    <div>
                      <label className={styles.question}>{answer.label}</label>
                    </div>
                    <div className={styles.area}>
                      <div className={styles.comment}>
                        <textarea
                          className={styles.textArea}
                          name='extra'
                          maxLength={250}
                          value={respExtra}
                          onChange={(e) => handleChange(e, 'extra')}></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {answer.type === 'threeOptions' && (
              <div className={styles.optionsInputs}>
                <div className={styles.separator}>
                  <input
                    type='text'
                    className={styles.inputText}
                    placeholder='Opción 1'
                    name='option'
                    value={option}
                    maxLength={50}
                    onChange={(e) => handleChange(e, 'option')}
                  />
                </div>
                <div className={styles.separator}>
                  <input
                    type='text'
                    className={styles.inputText}
                    placeholder='Opción 2'
                    name='option2'
                    value={option2}
                    maxLength={50}
                    onChange={(e) => handleChange(e, 'option2')}
                  />
                </div>
                <div className={styles.separator}>
                  <input
                    type='text'
                    className={styles.inputText}
                    placeholder='Opción 3'
                    name='option3'
                    value={option3}
                    maxLength={50}
                    onChange={(e) => handleChange(e, 'option3')}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={styles.count}>
            {answer.position} / {questions.length}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>

          <div className={styles.buttonContainer}>
            {questionActually > 0 ? (
              <div className={styles.buttonContainerAnt}>
                <Button
                  type='submit'
                  size='small'
                  color='primary'
                  className={`${styles.button}`}
                  onClick={() => removeQuestion(answer.position)}>
                  <span className={styles.buttonText}>&nbsp;Anterior&nbsp;</span>
                </Button>
              </div>
            ) : (
              <div className={styles.buttonContainerAnt}>&nbsp;</div>
            )}
            <div className={styles.buttonContainerSig}>
              <Button
                type='submit'
                size='small'
                color='primary'
                className={`${styles.button}`}
                onClick={() =>
                  movingQuestion(
                    answer.letter,
                    answer.num,
                    answer.type,
                    answer.extra,
                    answer.position,
                    answer.subquestion,
                    selectValor,
                  )
                }>
                <span className={styles.buttonText}>&nbsp;Siguiente&nbsp;</span>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>
          {loading ? (
            <div className={styles.finish}>
              <CircularProgress className={styles.circularProgress} />
            </div>
          ) : (
            <div className={styles.finish}>{message}</div>
          )}
        </div>
      )}
    </div>
  );
}
