import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './index.module.sass';

const defaultNotifications = process.env.REACT_APP_DEFAULT_NOTIFICATIONS || 'TMBdev';

export default function NewNotifications({
  idInit = '',
  statusInit = 'V',
  titleInit = '',
  descriInit = '',
  showStatus = false,
  callback,
}) {
  const [base64Image, setBase64Image] = useState('');
  const [imageName, setImageName] = useState('');
  const [notificationTitle, setNotificationTitle] = useState(titleInit);
  const [notificationDescription, setNotificationDescription] = useState(descriInit);
  const [loading, setLoading] = useState(false);
  const [estatus, setEstatus] = useState(statusInit);

  const convertBase64 = (file) => {
    setImageName(file.name);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getImage = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    const slpit64 = base64.split(',');

    if (slpit64.length > 1) {
      setBase64Image(slpit64[1]);
    }
  };

  function deleteNotification(id) {
    fetch(`${process.env.REACT_APP_WEBSERVICE}/deleteNotificacion?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { messageCode } = res;
        if (messageCode === 200) {
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  }

  function sendNotification() {
    if (!notificationTitle || !notificationDescription) {
      alert('Porfavor llena los cambios obligatorios');
      return;
    }

    setLoading(true);

    const webservice = idInit ? 'putNotificacion' : 'postAgregarNotificacion';

    let sendObject = {
      estatus: estatus,
      titulo: notificationTitle,
      descripcion: notificationDescription,
      imagen: imageName,
      fechaPublicacion: '',
      horaPublicacion: '',
      imagenBase64: base64Image,
      notificationTopic: defaultNotifications,
    };

    if (idInit) {
      sendObject.id = idInit;
    }

    fetch(`${process.env.REACT_APP_WEBSERVICE}/${webservice}`, {
      method: idInit ? 'PUT' : 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
      body: JSON.stringify(sendObject),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const { messageCode, message = '', id = '', typeMessage } = res;
        if (messageCode === 200 && typeMessage === 'success') {
          if (idInit) {
            alert('La notificación se editó correctamente');
            setLoading(false);
            if (callback) {
              callback();
            }
            return;
          }

          await fetch(
            `https://api.terramarbrands.com.mx/appnotifications?title=${encodeURIComponent(
              notificationTitle,
            )}&description=${encodeURIComponent(
              notificationDescription,
            )}&notificationID=${id}&notificationTopic=${defaultNotifications}&environment=${
              process.env.REACT_APP_ENV
            }`,
          )
            .then((response) => response.json())
            .then((json) => {
              const { message = 'error' } = json;
              if (message === 'success') {
                alert('La notificación se envió correctamente');
              } else {
                deleteNotification(id);
                alert('Hubo un error al intentar enviar la notifiación');
              }
            })
            .catch((error) => {
              deleteNotification(id);
              alert('No se pudo enviar la notifiación');
              console.log(error);
            });
        } else {
          alert(message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setLoading(false);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Título *</div>
          <input
            className={styles.input}
            type='text'
            value={notificationTitle}
            onChange={(e) => {
              setNotificationTitle(e.target.value);
            }}
            maxLength={30}
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputTitle}>Descripción *</div>
          <input
            className={styles.input}
            type='text'
            value={notificationDescription}
            onChange={(e) => {
              setNotificationDescription(e.target.value);
            }}
            maxLength={250}
          />
        </div>
        {showStatus && (
          <div className={styles.inputContainer}>
            <div className={styles.inputTitle}>Estatus</div>
            <select
              value={estatus}
              className={styles.input}
              onChange={(e) => {
                setEstatus(e.target.value);
              }}>
              <option value='V'>Vigente</option>
              <option value='C'>Cancelado</option>
            </select>
          </div>
        )}
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputTitle}>Imagen</div>
        <input
          type='file'
          accept='image/png, image/jpeg'
          onChange={(e) => {
            getImage(e);
          }}
          className={styles.inputfile}
        />
      </div>
      <div
        className={styles.button}
        onClick={() => {
          if (!loading) sendNotification();
        }}>
        {(loading && <CircularProgress className={styles.circularProgress} />) || 'Enviar'}
      </div>
    </div>
  );
}
