import React from 'react';
import styles from './index.module.sass';

import TableRow from '../TableRow';

const AtentionsTable = (props) => {
  const { table, screenSize, setRowSelected, setOpen, utf8Decode } = props;
  const tableHeader = [
    {
      title: 'Folio',
      phone: true,
    },
    {
      title: 'Area',
      phone: false,
    },
    {
      title: 'Departamento',
      phone: true,
    },
    {
      title: 'Fecha de captura',
      phone: true,
    },
    {
      title: 'Status',
      phone: false,
    },
    {
      title: 'Levantó',
      phone: false,
    },
    {
      title: 'Afectado',
      phone: false,
    },
    {
      title: 'Última respuesta',
      phone: false,
    },
    {
      title: 'Fecha de cierre',
      phone: false,
    },
  ];
  return (
    <div className={`${styles.atentionsTable} ${styles[screenSize]}`}>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          if (screenSize === 'phone' && val.phone === false) return false;
          else
            return (
              <div className={styles.headerItem} key={key}>
                {val.title}
              </div>
            );
        })}
      </div>
      {Object.entries(table).map((val, key) => {
        return (
          <TableRow
            key={key}
            val={(val && val[1]) || []}
            screenSize={screenSize}
            setRowSelected={setRowSelected}
            setOpen={setOpen}
            utf8Decode={utf8Decode}
          />
        );
      })}
    </div>
  );
};

export default AtentionsTable;
