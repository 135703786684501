const ES = {
  //Sign
  langSign: {
    title: 'Inicio de sesión',
    employeeInput: 'No. de Kit *',
    passInput: 'Contraseña *',
    buttonText: 'Ingresar',
    incorrectData: 'Datos incorrectos',
    permissionDenied: 'No tienes permisos para entrar a esta sección',
    incorrectInfo: 'Información incorrecta',
    altLogo: 'Logo de Terramar Brands',
  },
  langMenu: {
    atentions: 'Atenciones',
    indicators: 'Indicadores',
    products: 'Productos web',
    estafeta: 'Estafeta',
    dhl: 'DHL',
    dhlAvant: 'DHL Avant',
    paquete: 'Paquete express',
    rop: 'Formulario ROP',
    surveyadmin: 'Clima organizacional',
    survey: 'Encuesta clima organizacional',
    terrapedia: 'Terrapedia',
    logout: 'Cerrar sesión',
    loadSheet: 'Loadsheet',
    contratos: 'Contratos USA',
    digitalcatalog: 'Catálogo Digital',
    users: 'Habilitar usuarios',
    notifications: 'Notificationes',
    card: 'Tarjetas Si Vale',
    db2: 'DB2 web query',
  },
  langAtentions: {
    sectionName: 'Atenciones',
    wrongBrowser: 'Su navegador no permite esta acción',
    id: 'Folio',
    statusCaptured: 'Capturada',
    statusInProcess: 'En seguimiento',
    statusReplica: 'Réplica',
    statusClosed: 'Cerrada',
  },
};

export default ES;
