import React, { useEffect, useState, useCallback } from 'react';

import NotificationRow from './NotificationRow';

import styles from '../index.module.sass';

const rowWidth = '16.6666%';

export default function NotificationSent() {
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNotificationList = useCallback(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_WEBSERVICE}/getNotificaciones?desde=1&hasta=20`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        const { typeMessage, message, listDataNotificaciones = [] } = json;
        if (typeMessage === 'error') {
          alert(message);
        } else {
          setNotificationList(listDataNotificaciones);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getNotificationList();
  }, [getNotificationList]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.notiTable}>
        <div className={styles.rowHeader}>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Fecha de alta
          </div>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Fecha de publicación
          </div>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Título
          </div>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Usuario
          </div>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Estatus
          </div>
          <div
            className={`${styles.notiColumn} ${styles.centered}`}
            style={{
              width: rowWidth,
            }}>
            Acciones
          </div>
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          notificationList.map((notification, key) => {
            return (
              <NotificationRow
                notification={notification}
                key={key}
                callback={getNotificationList}
              />
            );
          })
        )}
      </div>
    </div>
  );
}
