import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styles from './index.module.sass';

import Menu from '../Menu';
import Atentions from '../Atentions';
import Indicators from '../Indicators';
import Loadsheet from '../Loadsheet';
import Products from '../Products';
import ProductForm from '../Products/ProductForm';
import CartProductForm from '../Loadsheet/CartProductForm';
import PromoForm from '../Loadsheet/PromoForm';
import Estafeta from '../Estafeta';
import DHL from '../DHL';
import DHLAvant from '../DHLAvant';
import PaqueteExpress from '../PaqueteExpress';
import EnableROP from '../EnableROP';
import SurveyAdmin from '../Survey';
import ContratosUSA from '../ContratosUSA';
import Survey from '../SurveyEmploye/Survey';
import Questions from '../SurveyEmploye/Questions';
import DigitalCatalog from '../DigitalCatalog';
import HomeTerrapedia from '../Terrapedia/Home';
import Notifications from '../Notifications';
import ActivacionUsuarios from '../ActivacionUsuarios';
import MenuSiVale from '../MenuSiVale';

function getCookie(name) {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

const Admin = (props) => {
  const { match, device, history, basename, clearSession, webserviceURL, webserviceURL2, Lang } =
    props;
  const { screenSize } = device;
  const [sectionName, setSectionName] = useState('Administración');
  const [open, setOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  //const [permissions, setPermissions] = useState({});
  const permissions = JSON.parse(localStorage.getItem('TMBadmin')) || {};
  const [token, setToken] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!localStorage.getItem('TMBadmin') || !localStorage.getItem('TMBtoken')) {
      clearSession();
      return;
    }
    //setPermissions(JSON.parse(localStorage.getItem('TMBadmin')));
    setToken(localStorage.getItem('TMBtoken'));
  }, [clearSession]);

  return (
    <div id={styles.adminContainer} className={styles[screenSize]}>
      <div className={styles.content}>
        <Menu
          device={device}
          history={history}
          match={match}
          open={open}
          setOpen={setOpen}
          optionSelected={optionSelected}
          basename={basename}
          clearSession={clearSession}
          getCookie={getCookie}
          webserviceURL={webserviceURL}
          permissions={permissions}
          token={token}
          Lang={Lang}
          setSectionName={setSectionName}
          setOptionSelected={setOptionSelected}
        />
        <div className={styles.main}>
          <div className={styles.header}>
            <i className='material-icons' onClick={() => setOpen(true)}>
              menu
            </i>
            <div className={styles.sectionName}>{sectionName}</div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.card}>
              <Switch>
                <Route
                  path={`${match.path}/atentions`}
                  render={(props) => {
                    return (
                      (permissions.atenciones === '1' && (
                        <Atentions
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />
                <Route
                  path={`${match.path}/indicators`}
                  render={(props) => {
                    return (
                      (permissions.indicadores === '1' && (
                        <Indicators
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/products/editproduct/:productId?`}
                  render={(props) => {
                    return (
                      (permissions.producto === '1' && (
                        <ProductForm
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/products`}
                  render={(props) => {
                    return (
                      (permissions.producto === '1' && (
                        <Products
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/estafeta`}
                  render={(props) => {
                    return (
                      (permissions.estafeta === '1' && (
                        <Estafeta
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/dhl`}
                  render={(props) => {
                    return (
                      (permissions.estafeta === '1' && (
                        <DHL
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          webserviceURL2={webserviceURL2}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/dhlAvant`}
                  render={(props) => {
                    return (
                      (permissions.estafeta === '1' && (
                        <DHLAvant
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          webserviceURL2={webserviceURL2}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/paqueteExpress`}
                  render={(props) => {
                    return (
                      (permissions.paqueteExpress === '1' && (
                        <PaqueteExpress
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          webserviceURL2={webserviceURL2}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/loadsheet/editproduct/:productId?`}
                  render={(props) => {
                    return (
                      (permissions.loadSheet === '1' && (
                        <CartProductForm
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/loadsheet/editpromotion/:promoSequence/:typeDiscPrize`}
                  render={(props) => {
                    return (
                      (permissions.loadSheet === '1' && (
                        <PromoForm
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/loadsheet`}
                  render={(props) => {
                    return (
                      (permissions.loadSheet === '1' && (
                        <Loadsheet
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/enableROP`}
                  render={(props) => {
                    return (
                      (permissions.rop === '1' && (
                        <EnableROP
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/surveyadmin`}
                  render={(props) => {
                    return (
                      (permissions.rh === '1' && (
                        <SurveyAdmin
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/survey`}
                  render={(props) => {
                    return (
                      <Survey
                        {...props}
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/questions`}
                  render={(props) => {
                    return (
                      <Questions
                        {...props}
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/terrapedia`}
                  render={(props) => {
                    return (
                      <HomeTerrapedia
                        {...props}
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/digitalcatalog`}
                  render={(props) => {
                    return (
                      (permissions.indicadores === '1' && (
                        <DigitalCatalog
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/contratosUSA`}
                  render={(props) => {
                    return (
                      (permissions.atenciones === '1' && (
                        <ContratosUSA
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/notifications`}
                  render={(props) => {
                    return (
                      (permissions.notificaciones === '1' && (
                        <Notifications
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/menuSiVale`}
                  render={(props) => {
                    return (
                      ((permissions.SiVale === '1' || permissions.SiVale === '2') && (
                        <MenuSiVale
                          {...props}
                          device={device}
                          history={history}
                          setSectionName={setSectionName}
                          setOptionSelected={setOptionSelected}
                          webserviceURL={webserviceURL}
                          getCookie={getCookie}
                          permissions={permissions}
                          token={token}
                          clearSession={clearSession}
                          Lang={Lang}
                        />
                      )) || <Redirect to={`${match.path}/withoutpermits`} />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/activacionUsuarios`}
                  render={(props) => {
                    return (
                      <ActivacionUsuarios
                        {...props}
                        device={device}
                        history={history}
                        setSectionName={setSectionName}
                        setOptionSelected={setOptionSelected}
                        webserviceURL={webserviceURL}
                        getCookie={getCookie}
                        permissions={permissions}
                        token={token}
                        clearSession={clearSession}
                        Lang={Lang}
                      />
                    );
                  }}
                />

                <Route
                  path={`${match.path}/withoutpermits`}
                  render={() => (
                    <h3 className={styles.please}>
                      No tienes los permisos necesarios para ingresar a esta opción.
                    </h3>
                  )}
                />
                <Route
                  path={`${match.path}`}
                  render={() => <h3 className={styles.please}>Por favor selecciona una opción.</h3>}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
