import React, { useState } from 'react';
import styles from './index.module.sass';
import InputFly from '../../../../misc/InputFly';
import SelectType from '../../../../misc/SelectType';
import Button from '@material-ui/core/Button';

import types from '../../../misc/type.js';

const Search = (props) => {
  const { searchFunction, searchObject, screenSize } = props;

  const [searchObjectTMP, setSearchObjectTMP] = useState({
    EXCEL: '',
    PAGENO: '1',
    ATEID: '', //folio
    ATEKIT: '', //kit
    ATEKITW: '', //afectedKit
    ATETIPO: '', //area
    ATESTS: '', //stsType
    DESDE: '', //from
    HASTA: '', //to
  });

  const handleChange = (e) => {
    setSearchObjectTMP({
      ...searchObjectTMP,
      [e.target.name]: e.target.value,
    });
  };

  const clear = () => {
    setSearchObjectTMP({
      EXCEL: '',
      PAGENO: '1',
      ATEID: '', //folio
      ATEKIT: '', //kit
      ATEKITW: '', //afectedKit
      ATETIPO: '', //area
      ATESTS: '', //stsType
      DESDE: '', //from
      HASTA: '', //to
    });
  };

  const handleSearch = (isExcel) => {
    const cloneSearch = {
      ...searchObject,
      ...searchObjectTMP,
      DESDE: searchObjectTMP.DESDE.replace(/-/g, ''),
      HASTA: searchObjectTMP.HASTA.replace(/-/g, ''),
      EXCEL: (isExcel && '1') || '0',
    };

    searchFunction(cloneSearch);
  };

  const sts = [
    {
      label: 'Capturada',
      value: 10,
    },
    {
      label: 'En seguimiento',
      value: 20,
    },
    {
      label: 'Réplica',
      value: 40,
    },
    {
      label: 'Cerrada',
      value: 80,
    },
  ];

  const areaOptions = types.map((val, key) => {
    return {
      value: val.value,
      label: val.principal,
    };
  });

  return (
    <div id={styles.Search} className={styles[screenSize]}>
      <div className={styles.inputsContainer}>
        <div className={styles.inputContent}>
          <InputFly
            className={styles.algo}
            handleChange={handleChange}
            name='ATEID'
            placeholder='Folio'
            type='text'
            value={searchObjectTMP.ATEID}
            maxlength='34'
          />
        </div>

        <div className={styles.inputContent}>
          <InputFly
            handleChange={handleChange}
            name='ATEKIT'
            placeholder='Kit que levantó'
            type='text'
            value={searchObjectTMP.ATEKIT}
            maxlength='34'
          />
        </div>

        <div className={styles.inputContent}>
          <InputFly
            handleChange={handleChange}
            name='ATEKITW'
            placeholder='Kit afectado'
            type='text'
            value={searchObjectTMP.ATEKITW}
            maxlength='34'
          />
        </div>

        <div className={styles.inputContent}>
          <div className={styles.dateContent}>
            <InputFly
              handleChange={handleChange}
              name='DESDE'
              placeholder='De la fecha'
              type='date'
              shrink={true}
              value={searchObjectTMP.DESDE}
              maxlength='34'
            />
          </div>
          <InputFly
            handleChange={handleChange}
            name='HASTA'
            placeholder='A la fecha'
            type='date'
            shrink={true}
            value={searchObjectTMP.HASTA}
            maxlength='34'
          />
        </div>
        <div className={styles.inputContent}>
          <SelectType
            handleChange={(e) => handleChange(e)}
            options={areaOptions}
            value={searchObjectTMP.ATETIPO}
            name='ATETIPO'
            placeholder='Area'
          />
        </div>
        <div className={styles.inputContent}>
          <SelectType
            handleChange={(e) => handleChange(e)}
            options={sts}
            value={searchObjectTMP.ATESTS}
            name='ATESTS'
            placeholder='Status'
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button} ${styles.normal}`}
          onClick={(e) => {
            handleSearch(false);
          }}>
          BUSCAR
        </Button>

        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button} ${styles.clean}`}
          onClick={(e) => {
            clear();
          }}>
          LIMPIAR
        </Button>

        <Button
          type='submit'
          variant='contained'
          size='large'
          color='primary'
          className={`${styles.button} ${styles.excel}`}
          onClick={(e) => {
            handleSearch(true);
          }}>
          DESCARGAR EXCEL
        </Button>
      </div>
    </div>
  );
};

export default Search;
