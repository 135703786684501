import React, { useState, useEffect } from 'react';
import styles from './index.module.sass';
import InputFly from '../../misc/InputFly';
import Button from '@material-ui/core/Button';
import usStates from './usStates';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '../Alert';

// Queries
import AvalaraResolveAddress from './AvalaraResolveAddress';

// Dependencies
import superagent from 'superagent';

const ContratosUSA = (props) => {
  const { screenSize, setOptionSelected, setSectionName, webserviceURL } = props;
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const [found, setfound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAddress, setloadingAddress] = useState(false);
  const [form, setForm] = useState({
    kit: '',
    nombre: '',
    apellido: '',
    nacimiento: '',
    sexo: '',
    calle: '',
    entreCalle: '',
    ciudad: '',
    estado: '',
    cp: '',
    celular: '',
    email: '',
    validado: false,
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      validado: false,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => {
    setLoading(true);

    const json = {
      accion: '',
      usuarioOK: form.kit || '',
    };
    const url = `${webserviceURL}/postContratoActualiza`;

    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .send(json)
      .set('token', localStorage.getItem('TMBtoken'))
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .then((res) => {
        const { body = {} } = res;
        const { mensajeValidacionDatos } = body;
        if (mensajeValidacionDatos === '') {
          setForm({
            kit: body.usuarioOK,
            nombre: body.nombre,
            apellido: `${body.paterno} ${body.materno}`,
            nacimiento: body.fechaNac,
            sexo: body.sexo,
            calle: body.calle,
            entreCalle: body.entreCalle,
            ciudad: body.ciudad,
            estado: body.estado,
            cp: body.cp,
            celular: body.celular,
            email: body.email,
            patrocinadora: `${body.kitPatrocinadora} ${body.nombrePatrocinadora}`,
            validado: false,
          });
          setfound(true);
        } else {
          setfound(false);
          setAlertText(mensajeValidacionDatos);
          setOpen(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertText('An error occurred');
        setOpen(true);
      });
  };

  async function handleValidate() {
    setloadingAddress(true);
    const { calle = '', ciudad = '', estado = '', cp = '', entreCalle = '' } = form;

    if (form.calle === '' || form.ciudad === '' || form.estado === '' || form.cp === '') {
      setAlertText('Favor de llevar los campos requeridos');
      setOpen(true);
      return;
    }
    const resp = await AvalaraResolveAddress({
      jsonBody: {
        line1: calle,
        line2: entreCalle,
        city: ciudad,
        region: estado,
        postalCode: cp,
        country: 'US',
      },
    });

    const { message, tipoMensaje, validatedAddresses = [] } = resp;

    if (tipoMensaje === 'success' && validatedAddresses[0]) {
      const { city, line1, line2, region, postalCode } = validatedAddresses[0];

      if (region === 'MT') {
        setAlertText('Por el momento no es posible dar de alta el estado de Montana');
        setOpen(true);
        return;
      } else {
        setForm({
          ...form,
          validado: true,
          calle: line1,
          entreCalle: line2,
          estado: region,
          ciudad: city,
          cp: postalCode,
        });
        setloadingAddress(false);
      }
    } else if (tipoMensaje === 'inspect') {
      setAlertText(message);
      setOpen(true);
      setForm({
        ...form,
        validado: false,
      });
      setloadingAddress(false);
    } else {
      setAlertText('Validation error');
      setOpen(true);
      setForm({
        ...form,
        validado: false,
      });
      setloadingAddress(false);
    }
  }

  function submit() {
    const json = {
      accion: 'TERMINAR',
      usuarioOK: form.kit,
      email: '',
      emailValidado: '',
      calle: form.calle,
      numeroExterior: '',
      numeroInterior: '',
      manzana: '',
      edificio: '',
      lote: '',
      depCasa: '',
      cp: form.cp,
      colonia: form.estado,
      entreCalle: form.entreCalle,
      yCalle: '',
      telefonoCasa: '',
      celular: '',
      telefonoOficina: '',
      ciudad: form.ciudad,
    };
    const url = `${webserviceURL}/postContratoActualiza`;
    superagent
      .post(url)
      .set('Content-Type', 'application/json')
      .send(json)
      .set('token', localStorage.getItem('TMBtoken'))
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .then((res) => {
        const { body = {} } = res;
        if (body.tipoMensaje === 'exito') {
          setAlertText(body.accionSiguienteMensaje);
          setOpen(true);
          setfound(false);
        } else {
          setAlertText('Error al actualizar, intentalo de nuevo');
          setOpen(true);
          setfound(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlertText('An error occurred');
        setOpen(true);
      });
  }

  useEffect(() => {
    setOptionSelected('contratos');
    setSectionName('Contratos USA');
  }, [setOptionSelected, setSectionName]);

  return (
    <div id={styles.Search} className={styles[screenSize]}>
      <div className={styles.inputsContainer}>
        <Alert open={open} setOpen={setOpen} title='Mensaje del sistema' text={alertText} />
        <div className={styles.inputContent}>
          <InputFly
            className={styles.algo}
            handleChange={(e) => handleChange(e)}
            name='kit'
            placeholder='Buscar Kit'
            type='text'
            value={form.kit}
            maxlength='34'
          />
        </div>
        <div className={styles.inputContent}>
          <Button
            type='button'
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button} ${styles.normal}`}
            onClick={(e) => {
              handleSearch(false);
            }}>
            BUSCAR
          </Button>
        </div>
      </div>

      {loading === true ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div className={`${found === true ? styles.kitFound : styles.kitNoFound}`}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <label className={styles.label}>Kit:</label>
              {form.kit}
            </div>

            <div className={styles.inputContent}>
              <label className={styles.label}>Nombre:</label> {form.nombre} {form.apellido}
            </div>
          </div>

          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <label className={styles.label}>Fecha de nacimiento:</label> {form.nacimiento}
            </div>
            <div className={styles.inputContent}>
              <label className={styles.label}>Sexo:</label> {form.sexo}
            </div>
          </div>

          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <label className={styles.label}>Patrocinadora:</label> {form.patrocinadora}
            </div>
          </div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <label className={styles.label}>Celular:</label> {form.celular}
            </div>
            <div className={styles.inputContent}>
              <label className={styles.label}>Correo:</label> {form.email}
            </div>
          </div>

          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <InputFly
                handleChange={(e) => handleChange(e)}
                name='calle'
                placeholder='Dirección'
                type='text'
                value={form.calle}
              />
            </div>
            <div className={styles.inputContent}>
              <InputFly
                handleChange={(e) => handleChange(e)}
                name='entreCalle'
                placeholder='Complemento de dirección'
                type='text'
                value={form.entreCalle}
              />
            </div>
          </div>

          <div className={styles.inputsContainer}>
            <div className={styles.inputContent}>
              <InputFly
                handleChange={(e) => handleChange(e)}
                name='ciudad'
                placeholder='Ciudad'
                type='text'
                value={form.ciudad}
              />
            </div>
            <div className={styles.inputContent}>
              <select
                className={styles.select}
                name='estado'
                onChange={(e) => handleChange(e)}
                value={form.estado}>
                <option value=''>-- Selecciona un estado --*</option>
                {usStates.map((val, key) => {
                  return (
                    <option key={key} value={val.value}>
                      {val.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={styles.inputContent}>
              <InputFly
                handleChange={(e) => handleChange(e)}
                name='cp'
                placeholder='Código postal'
                type='text'
                value={form.cp}
              />
            </div>
            <div className={styles.inputContent}>
              <Button
                type='button'
                variant='contained'
                size='large'
                color='primary'
                className={`${styles.button} ${styles.normal}`}
                onClick={(e) => {
                  handleValidate();
                }}>
                VALIDAR DIRECCIÓN
              </Button>
              {loadingAddress && <CircularProgress />}
            </div>
          </div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContentDir}>
              {form.validado === true ? (
                <label className={styles.validate}>Dirección validada</label>
              ) : (
                <label className={styles.noValidate}>Dirección no validada</label>
              )}
            </div>
          </div>
          <div className={styles.inputContent}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              color='primary'
              className={form.validado === true ? styles.button : styles.buttonDisable}
              onClick={(e) => {
                submit();
              }}
              disabled={form.validado === false}>
              ACTUALIZAR
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContratosUSA;
