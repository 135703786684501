const subdeptos = [
  [
    //Cuidado de la piel
    {
      value: '1',
      label: 'Limpieza',
      labelEng: 'Cleaning',
    },
    {
      value: '2',
      label: 'Hidratación',
      labelEng: 'Hydration',
    },
    {
      value: '3',
      label: 'Control de grasa',
      labelEng: 'Oil control',
    },
    {
      value: '4',
      label: 'Cuidado de ojos',
      labelEng: 'Eye care',
    },
    {
      value: '5',
      label: 'Especialidades',
      labelEng: 'Specialties',
    },
    {
      value: '6',
      label: 'Proteccion solar',
      labelEng: 'Sun protection',
    },
    {
      value: '7',
      label: 'Hombres',
      labelEng: 'Men',
    },
  ],
  [
    //Cuidado capilar
    {
      value: '1',
      label: 'Uso diario',
      labelEng: 'Daily use',
    },
  ],
  [
    //Baño y Cuerpo
    {
      value: '1',
      label: 'Antitranspirantes',
      labelEng: 'Antiperspirants',
    },
    {
      value: '2',
      label: 'Higiene intima',
      labelEng: 'Intimate care',
    },
  ],
  [
    //Cuidado del Cuerpo
    {
      value: '1',
      label: 'Tratamientos',
      labelEng: 'Treatments',
    },
    {
      value: '2',
      label: 'Mantequillas naturales',
      labelEng: 'TreaNatural butterstments',
    },
  ],
  [
    //Maquillaje
    {
      value: '1',
      label: 'Rostro',
      labelEng: 'Face',
    },
    {
      value: '2',
      label: 'Ojos',
      labelEng: 'Eyes',
    },
    {
      value: '3',
      label: 'Labios',
      labelEng: 'Lips',
    },
    {
      value: '4',
      label: 'Uñas',
      labelEng: 'Nail',
    },
    {
      value: '5',
      label: 'Desmaquillantes',
      labelEng: 'Makeup remover',
    },
  ],
  [
    //Fragrances
    {
      value: '1',
      label: 'Ella',
      labelEng: 'Her',
    },
    {
      value: '2',
      label: 'El',
      labelEng: 'Him',
    },
    {
      value: '3',
      label: 'Niños',
      labelEng: 'Kids',
    },
  ],
  [
    //CC Cream
    {
      value: '1',
      label: 'CC Cream',
      labelEng: 'CC Cream',
    },
  ],
];

export default subdeptos;
