import React from 'react';
import styles from './index.module.sass';

import TableRow from './TableRow';

const QNCS = (props) => {
  const { indicatorArray, rowSelected, setRowSelected } = props;
  const tableHeader = [
    'Año 1',
    'Mes 1',
    'Día 1',
    'Qncs 1',
    'Total 1',
    'Año 2',
    'Mes 2',
    'Día 2',
    'Qncs 2',
    'Total 2',
    'Año 3',
    'Mes 3',
    'Día 3',
    'Qncs 3',
    'Total 3',
  ];

  return (
    <div className={styles.indicatorTable}>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          return (
            <div className={styles.headerItem} key={key}>
              {val}
            </div>
          );
        })}
      </div>
      {indicatorArray.map((val, key) => {
        const order = [
          val.TINYEAR1,
          val.TINMES1,
          val.TINDIA1,
          val.TINQNC1,
          (val.TINQNCT1 && `${val.TINQNCT1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINQNCT1,
          val.TINYEAR2,
          val.TINMES2,
          val.TINDIA2,
          val.TINQNC2,
          (val.TINQNCT2 && `${val.TINQNCT2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINQNCT2,
          val.TINYEAR3,
          val.TINMES3,
          val.TINDIA3,
          val.TINQNC3,
          (val.TINQNCT3 && `${val.TINQNCT3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINQNCT3,
        ];
        return (
          <TableRow
            key={key}
            row={key}
            order={order}
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
          />
        );
      })}
    </div>
  );
};

export default QNCS;
