const deptos = [
  {
    value: '1',
    label: 'Cuidado de la piel',
    labelEng: 'Skin Care',
  },
  {
    value: '2',
    label: 'Cuidado capilar',
    labelEng: 'Hair Care',
  },
  {
    value: '3',
    label: 'Baño y Cuerpo',
    labelEng: 'Bath and Body',
  },
  {
    value: '4',
    label: 'Cuidado del Cuerpo',
    labelEng: 'Body Care',
  },
  {
    value: '5',
    label: 'Maquillaje',
    labelEng: 'Makeup',
  },
  {
    value: '6',
    label: 'Fragancias',
    labelEng: 'Fragrances',
  },
  {
    value: '7',
    label: 'CC Cream',
    labelEng: 'CC cream',
  },
];

export default deptos;
