import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.sass';
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';

import Search from './components/Search';
import AtentionsTable from './components/AtentionsTable';
import Chat from './components/Chat';

import PaginatorList from './components/PaginatorList';

const perpage = 30;

const utf8Decode = (utf8String) => {
  if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  const unicodeString = utf8String
    .replace(
      /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc =
          ((c.charCodeAt(0) & 0x0f) << 12) |
          ((c.charCodeAt(1) & 0x3f) << 6) |
          (c.charCodeAt(2) & 0x3f);
        return String.fromCharCode(cc);
      },
    )
    .replace(
      /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
      function (c) {
        // (note parentheses for precedence)
        var cc = ((c.charCodeAt(0) & 0x1f) << 6) | (c.charCodeAt(1) & 0x3f);
        return String.fromCharCode(cc);
      },
    );
  return unicodeString;
};

const getSafeValue = (StringValue) => {
  StringValue = (StringValue || '') + '';
  StringValue = StringValue.replace(/"/g, '').replace(/\n/g, ' ');
  StringValue = `"${StringValue}"`;

  return StringValue;
};

const Atentions = (props) => {
  const {
    setSectionName,
    device,
    setOptionSelected,
    webserviceURL,
    permissions,
    getCookie,
    token,
    clearSession,
    Lang = {},
  } = props;
  const { langAtentions = {} } = Lang;
  const { screenSize } = device;
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState({});
  const [dateTable, setDateTable] = useState({});
  const [mainData, setMainData] = useState({});
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [init, setInit] = useState(false);

  if (loading && dateTable && mainData && totalPages) {
    //console.log('solo para usarse');
  }

  const [rowSelected, setRowSelected] = useState({
    area: '',
    areaDescri: '',
    depto: '',
    deptoDescri: '',
    descripcion: '',
    email: '',
    fechaCaptura: '',
    fechaCierre: '',
    folio: '',
    imagen: '',
    kit: '',
    kitAfectado: '',
    kitSeguimiento: '',
    nombre: '',
    status: '',
    statusDescri: '',
  });

  const [searchObject, setSearchObject] = useState({
    EXCEL: '',
    PAGENO: '1',
    ATEID: '', //folio
    ATEKIT: '', //kit
    ATEKITW: '', //afectedKit
    ATETIPO: '', //area
    ATESTS: '', //stsType
    DESDE: '', //from
    HASTA: '', //to
  });

  const createExcel = useCallback(
    (excelData) => {
      var csv = '';
      var header = [
        'FOLIO', //ATEID
        'AREA', //ATETIPOD
        'DEPARTAMENTO', //ATESTIPOD
        'FECHA CAPTURA', //ATEFECI
        'ESTATUS', //ATESTS
        'KIT', //ATEKIT
        'NOMBRE', //ATENOM
        'KIT AFECTADO', //ATEKITW
        'DESCRIPCIÓN', //ATESDESC
        'FECHA DE CIERRE', //ATEFECC
      ];

      header.map((val, key) => {
        csv += val + ',';
        return true;
      });
      csv += '\n';

      excelData.map((val, key) => {
        var status = '';
        if (val.status === '10') status = langAtentions.statusCaptured || '';
        if (val.status === '20') status = langAtentions.statusInProcess || '';
        if (val.status === '40') status = langAtentions.statusReplica || '';
        if (val.status === '80') status = langAtentions.statusClosed || '';

        var fecha = val.fechaCaptura;

        var fechacierre = val.fechaCierre;

        csv += getSafeValue(val.folio);
        csv += ',';
        csv += getSafeValue(utf8Decode(val.areaDescri));
        csv += ',';
        csv += getSafeValue(utf8Decode(val.deptoDescri));
        csv += ',';
        csv += getSafeValue(fecha);
        csv += ',';
        csv += getSafeValue(status);
        csv += ',';
        csv += getSafeValue(val.kit);
        csv += ',';
        csv += getSafeValue(val.nombre);
        csv += ',';
        csv += getSafeValue(val.kitAfectado);
        csv += ',';
        csv += getSafeValue(val.descripcion);
        csv += ',';
        csv += getSafeValue(fechacierre);
        csv += '\n';

        return true;
      });

      if (window.Blob && (window.URL || window.webkitURL)) {
        var blob, reader, save, clicEvent;

        //creamos el blob
        blob = new Blob(['\ufeff', csv], { type: 'text/csv' });
        //creamos el reader
        reader = new FileReader();
        reader.onload = (event) => {
          //escuchamos su evento load y creamos un enlace en dom
          save = document.createElement('a');
          save.href = event.target.result;
          save.target = '_blank';
          //aquí le damos nombre al archivo
          //save.download = `log_${d.getDate()}_${d.getMonth()+1}_${d.getFullYear()}.csv`;
          save.download = 'atentions.csv';
          try {
            //creamos un evento click
            clicEvent = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            });
          } catch (e) {
            //si llega aquí es que probablemente implemente la forma antigua de crear un enlace
            clicEvent = document.createEvent('MouseEvent');
            clicEvent.initEvent('click', true, true);
          }
          //disparamos el evento
          save.dispatchEvent(clicEvent);
          //liberamos el objeto window.URL por si estuviera usado
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        };
        //leemos como url
        reader.readAsDataURL(blob);
        setLoading(false);
      } else {
        //el navegador no admite esta opción
        alert(langAtentions.wrongBrowser || '');
        setLoading(false);
      }
    },
    [langAtentions],
  );

  const searchFunction = useCallback(
    (obj) => {
      if (obj.EXCEL === '1') {
        setLoading(true);
      } else {
        setTable({});
        setLoading(true);
        setMainData({});
        setSearchObject(obj);
        setTotalPages([]);
      }

      const url = `${webserviceURL}/getAtenciones`;

      superagent
        .post(url)
        .set('Content-Type', 'application/json')
        .send(obj)
        .set('token', token)
        .auth(token, { type: 'bearer' })
        .then((res) => {
          const { body = {} } = res;
          const { atenciones, count, mensaje } = body;
          if (mensaje === 'SESION EXPIRADA') {
            alert('Tu sesión a expirado');
            clearSession();
            return;
          }
          if (atenciones) {
            if (obj.EXCEL === '1') {
              createExcel(atenciones);
            } else {
              let tmpArr = new Array(perpage);
              let tmpTable = {};
              let tmpDateTable = {};
              let arr = [];

              for (let i = 0; i < tmpArr.length; i++) {
                if (atenciones[i]) {
                  tmpTable[i] = atenciones[i];
                }
              }

              arr.map((val, key) => {
                tmpDateTable[key] = val;
                return true;
              });

              setTable(tmpTable);
              setDateTable(tmpDateTable);
              setLoading(false);
              setMainData(atenciones);

              let pages = Array.apply(null, Array((count && Math.ceil(count / perpage)) || 1)).map(
                (val, idx) => idx,
              );

              setTotalPages(pages);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [webserviceURL, token, clearSession, createExcel],
  );

  const paginator = (p) => {
    const tmpObject = JSON.parse(JSON.stringify(searchObject));
    tmpObject.PAGENO = p;

    searchFunction(tmpObject);

    setPage(p);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSectionName(langAtentions.sectionName || '');
    setOptionSelected('atentions');
    if (!token || init) return;
    setInit(true);
    searchFunction(searchObject);
  }, [searchObject, setOptionSelected, setSectionName, searchFunction, token, init, langAtentions]);

  return (
    <div id={styles.Atentions} className={styles[screenSize]}>
      {permissions.atenciones === '1' && (
        <>
          {open && (
            <Chat
              screenSize={screenSize}
              rowSelected={rowSelected}
              setOpen={setOpen}
              open={open}
              webserviceURL={webserviceURL}
              getCookie={getCookie}
              searchFunction={searchFunction}
              searchObject={searchObject}
              token={token}
              langAtentions={langAtentions}
            />
          )}
          <Search
            screenSize={screenSize}
            searchFunction={searchFunction}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            utf8Decode={utf8Decode}
            langAtentions={langAtentions}
          />
          {(loading && <CircularProgress />) || (
            <AtentionsTable
              screenSize={screenSize}
              table={table}
              setRowSelected={setRowSelected}
              setOpen={setOpen}
              webserviceURL={webserviceURL}
              utf8Decode={utf8Decode}
              langAtentions={langAtentions}
            />
          )}
          <PaginatorList
            totalPages={totalPages}
            searchFunction={searchFunction}
            searchObject={searchObject}
            setPage={setPage}
            page={page}
            paginator={paginator}
            langAtentions={langAtentions}
          />
        </>
      )}
    </div>
  );
};

export default Atentions;
