import React, { useCallback, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import Alert from '../Alert';

//Queries
import postTerrapediaArticle from '../Queries/postTerrapediaArticle';

//Styles
import styles from './index.module.sass';

// Images
import ARROW from '../../../images/sign/chevron.svg';

export default function HomeDetail({ action, themeActually = '', optionActually = '' }) {
  const [content, setcontent] = useState('');
  const [theme, settheme] = useState('');
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [loading, setloading] = useState(true);

  const loadArticle = useCallback(async () => {
    if (themeActually !== '' && optionActually !== '') {
      setloading(true);
      const res = await postTerrapediaArticle('CONSULTAR', themeActually, optionActually);
      const { TypeMessage = 'error', Article = '', Message, FileName = '', Theme = '' } = res;
      if (TypeMessage === 'success') {
        setcontent(Article);
        settheme(Theme);
        setFile(FileName);
      } else {
        setAlertText(Message);
        setOpen(true);
      }
      setloading(false);
    }
  }, [themeActually, optionActually]);

  useEffect(() => {
    loadArticle();
  }, [loadArticle]);

  return (
    <div className={styles.container}>
      <Alert open={open} setOpen={setOpen} title='Terrapedia dice:' text={alertText} />
      {!loading ? (
        <div>
          <div className={styles.containerTitle}>
            <div className={styles.title}>
              {themeActually} <img src={ARROW} alt='' /> {theme}
            </div>
          </div>
          <div className={styles.content}>
            {content.toString().replaceAll('+', '\n').replaceAll(',', '')}
          </div>

          {file !== '' && (
            <div className={styles.highlight}>
              <div className={styles.highlightTitle}>Archivo de apoyo</div>
              <div className={styles.highlightContent}>
                <a href={`https://docs.terramarbrands.com.mx/terrapedia/${file}`}>{file}</a>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Box>
          <Skeleton variant='rounded' height={30} className={styles.loadingText} />
          <Skeleton variant='rounded' height={100} className={styles.loadingText} />
        </Box>
      )}
    </div>
  );
}
