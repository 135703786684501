import React, { useEffect, useState } from 'react';
import styles from './index.module.sass';

import Detail from './Detail';
import General from './General';

const DigitalCatalog = (props) => {
  const { webserviceURL, setOptionSelected, device, setSectionName } = props;
  const { screenSize } = device;
  const [menuSelected, setMenuSelected] = useState('general');

  useEffect(() => {
    setOptionSelected('digitalcatalog');
    setSectionName('Catálogo digital');
  }, [setOptionSelected, setSectionName]);

  return (
    <div className={`${styles.container} ${styles[screenSize]}`}>
      <div className={styles.miniMenu}>
        <div className={styles.miniMenuOption} onClick={() => setMenuSelected('general')}>
          General
        </div>
        <div className={styles.miniMenuOption} onClick={() => setMenuSelected('detalle')}>
          Detalle
        </div>
      </div>
      {menuSelected === 'general' && <General webserviceURL={webserviceURL} device={device} />}
      {menuSelected === 'detalle' && <Detail webserviceURL={webserviceURL} device={device} />}
    </div>
  );
};

export default DigitalCatalog;
