import React from 'react';

// Images
import terrapedia from '../../../images/sign/terrapedia-imagen-men.png';

// styles
import styles from './styles.module.sass';

export default function HomeDetail({ screenSize }) {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleUnderline}>TERRAPEDIA</div>
        <div className={styles.title}>Mantenimiento y consulta</div>
        <div className={styles.subtitle}>DE TERRAPEDIA</div>
        <div className={styles.text}>
          Aquí en Terrapedia podras consultar como hacer uso de las herramientas de tu área; así
          mismo darle mantenimiento y subir nuevos tutoriales de uso para guíar a los usuarios en
          funciones especificas.
        </div>
        {screenSize === 'phone' && (
          <div>
            <img src={terrapedia} alt='Terrapedia' className={styles.image} />
          </div>
        )}
      </div>
      {screenSize !== 'phone' && (
        <div>
          <img src={terrapedia} alt='Terrapedia' className={styles.image} />
        </div>
      )}
    </div>
  );
}
