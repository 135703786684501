import React, { useEffect, useState } from 'react';

//Styles
import styles from './index.module.sass';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

// Dependencies
import superagent from 'superagent';
import ReactExport from 'react-export-excel-fixed-xlsx';

export default function DownloadSiVale(props) {
  const { webserviceURL, setOptionSelected, setSectionName } = props;
  const [loading, setloading] = useState(true);
  const [tableData, settableData] = useState([]);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    setloading(true);
    setOptionSelected('downloadSiVale');
    setSectionName('Descargar listado de tarjetas');
    const url = `${webserviceURL}/GetSiValeActivas`;

    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .set('token', localStorage.getItem('TMBtoken'))
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .then((res) => {
        if (res.body.typeMessage === 'success') {
          settableData(res.body.data);
        } else {
          alert(res.body.message);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        alert('Ocurrio un error');
      });
  }, [setOptionSelected, setSectionName, webserviceURL]);

  return (
    <div className={styles.form}>
      <div className={styles.divButton}>
        <ExcelFile
          element={
            <Button
              type='button'
              variant='contained'
              size='large'
              color='primary'
              className={styles.button}>
              <i className='material-icons'>file_download</i>
              Descargar
            </Button>
          }
          filename='TarjetasSiVale'>
          <ExcelSheet data={tableData} name='SiVale'>
            <ExcelColumn label='numTarjeta' value='numTarjeta' />
            <ExcelColumn label='IUT' value='iut' />
            <ExcelColumn label='Kit' value='Kit' />
            <ExcelColumn label='Fecha' value='Fecha' />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <div className={styles.table}>
        <div className={styles.th}>
          <div className={styles.row}>Número de tarjeta</div>
          <div className={styles.row}>IUT</div>
          <div className={styles.row}>KIT</div>
          <div className={styles.row}>Fecha</div>
        </div>
        {!loading ? (
          <>
            {tableData.map((values, key) => {
              return (
                <div className={styles.td} key={key}>
                  <div className={styles.row}>{values.numTarjeta}</div>
                  <div className={styles.row}>{values.iut}</div>
                  <div className={styles.row}>{values.Kit}</div>
                  <div className={styles.row}>{values.Fecha}</div>
                </div>
              );
            })}
          </>
        ) : (
          <div className={styles.divLoading}>
            <CircularProgress className={styles.circular} />
          </div>
        )}
      </div>
    </div>
  );
}
