import React, { useEffect, useState, useCallback } from 'react';
import styles from './index.module.sass';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Dependencies
import superagent from 'superagent';

const Survey = (props) => {
  const { webserviceURL, setOptionSelected, setSectionName } = props;
  const [tableData, settableData] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    setOptionSelected('surveyadmin');
    setSectionName('Clima Organizacional');
    const url = `${webserviceURL}/surveyList`;

    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .set('token', localStorage.getItem('TMBtoken'))
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .then((res) => {
        if (res.body.TypeMessage === 'success') {
          settableData(res.body.Replies);
        } else {
          alert(res.body.Message);
        }
      })
      .catch((err) => {
        console.log(err);
        alert('An error occurred');
      });
    setloading(false);
  }, [webserviceURL, setOptionSelected, setSectionName]);

  const downloadExcel = useCallback(() => {
    var csv = '';
    var header = [
      'Departamento',
      'Identificador',
      'Pregunta',
      'Respuesta',
      'Observacion 1',
      'Observacion 2',
      'Observacion 3',
    ];

    header.map((val, key) => {
      csv += val + ',';
      return true;
    });
    csv += '\n';

    tableData.map((val, key) => {
      csv += val.Department.replaceAll('\n', ' ');
      csv += ',';
      csv += val.SurveyNumber.replaceAll('\n', ' ');
      csv += ',';
      csv += val.QuestionNumber.replaceAll('\n', ' ');
      csv += ',';
      csv += val.Reply.replaceAll('\n', ' ');
      csv += ',';
      csv += val.Observation1.replaceAll('\n', ' ');
      csv += ',';
      csv += val.Observation2.replaceAll('\n', ' ');
      csv += ',';
      csv += val.Observation3.replaceAll('\n', ' ');
      csv += '\n';
      return true;
    });

    if (window.Blob && (window.URL || window.webkitURL)) {
      var blob, reader, save, clicEvent;

      blob = new Blob(['\ufeff', csv], { type: 'text/csv' });
      reader = new FileReader();
      reader.onload = (event) => {
        save = document.createElement('a');
        save.href = event.target.result;
        save.target = '_blank';
        save.download = 'climaLaboral.csv';
        try {
          clicEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          });
        } catch (e) {
          clicEvent = document.createEvent('MouseEvent');
          clicEvent.initEvent('click', true, true);
        }
        save.dispatchEvent(clicEvent);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      };
      reader.readAsDataURL(blob);
    } else {
      alert('Error al descargar');
    }
  }, [tableData]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Resultados de encuesta Clima Organizacional</div>
      {loading ? (
        <CircularProgress className={styles.circularProgress} />
      ) : (
        <Button
          type='button'
          variant='contained'
          size='large'
          color='primary'
          className={styles.button}
          onClick={() => downloadExcel()}>
          <i className='material-icons'>file_download</i>
          Descargar
        </Button>
      )}
    </div>
  );
};

export default Survey;
