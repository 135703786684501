import React from 'react';

import styles from './index.module.sass';

const ActivacionUsuarios = (props) => {
  return (
    <div id={styles.Search}>
      <iframe
        width='95%'
        height='300'
        src='http://192.168.10.24:5001/TimwarePASS/index.html'
        frameBorder='0'
        // allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen='allowfullscreen'
        className={styles.frame}
        title='TMBVideos'></iframe>
    </div>
  );
};

export default ActivacionUsuarios;
