import React, { useEffect, useCallback, useState } from 'react';
import styles from '../index.module.sass';

//Dependencies
import superagent from 'superagent';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const Discounts = (props) => {
  const { setLoading, match, getCookie, year, month, catSelected, loading } = props;
  const [promotions, setPromotions] = useState([]);

  const getPromotions = useCallback(() => {
    setLoading(true);
    const yearSelected = getCookie('yearSelected') || year + '';
    const monthSelected = getCookie('monthSelected') || month;

    const url = `http://intranettb.dyndns.org:10000/web/services/getPromotions?yearMonth=${yearSelected}${monthSelected}`;
    superagent
      .get(url)
      .then((res) => {
        const { body = {} } = res;
        const { typeMessage = 'error', message = '', getPromovresult = [] } = body;

        if (typeMessage !== 'error') {
          setPromotions(getPromovresult);
        } else {
          alert(message);
          setPromotions([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        alert('ERROR EN LOADSHEET DISCOUNT');
        console.log(err, 'ERROR EN LOADSHEET DISCOUNT');
        setLoading(false);
      });
  }, [setLoading, getCookie, year, month]);

  const deletePrize = (prize) => {
    setLoading(true);

    const yearSelected = getCookie('yearSelected') || year;
    const monthSelected = getCookie('monthSelected') || month;

    let question = window.confirm('¿Eliminar promoción?');
    if (question) {
      setPromotions([]);
      let jsonBody = Object.assign({}, prize);
      jsonBody.yearMonth = `${yearSelected}${monthSelected}`;
      jsonBody.typeRecord = 'B';
      delete jsonBody.category;
      //const url = `${webserviceURL}/postPromo`;
      const url = `http://intranettb.dyndns.org:10000/web/services/postPromo`;

      superagent
        .post(url)
        .set('Content-Type', 'application/json')
        .send(jsonBody)
        .then((res) => {
          if (res.body) {
            const { body = {} } = res;
            const { message = '' } = body;
            window.scrollTo(0, 0);
            alert(message);
            getPromotions();
          } else {
            alert('An error occurred, please try again');
          }
        })
        .catch((err) => {
          console.log(err);
          alert('An error occurred');
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPromotions();
  }, [getPromotions, catSelected]);

  return (
    <div id={styles.Discount}>
      {/*(getCookie('yearSelected') >= year && getCookie('monthSelected') >= month) && <div className={styles.buttonNewProduct}>
            <Link to={`${match.path}editpromotion/newprize/newprize`}>
                <Button
                    type='button'
                    variant='contained'
                    size='large'
                    color='primary'
                    className={`${styles.button}`}>
                    Nuevo premio/descuento
                </Button>
            </Link>
        </div>*/}

      <div className={styles.buttonNewProduct}>
        <Link to={`${match.path}editpromotion/newprize/newprize`}>
          <Button
            type='button'
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button}`}>
            Nuevo premio/descuento
          </Button>
        </Link>
      </div>

      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}

      <div className={styles.productList}>
        <div className={`${styles.productRow} ${styles.header}`}>
          <div className={`${styles.headerColumn}`}>Secuencia</div>
          <div className={`${styles.headerColumn}`}>Código de producto</div>
          <div className={`${styles.headerColumn}`}>Tipo</div>
          <div className={`${styles.headerColumn}`}>Día de inicio</div>
          <div className={`${styles.headerColumn}`}>Día de fin</div>
          <div className={`${styles.headerActions}`}>Acciones</div>
        </div>

        {promotions.map((val, key) => {
          const { promoSequence, productCode, typeDiscPrize, initialDay, finalDay } = val;
          let discountType =
            (typeDiscPrize === 'D' && 'Descuento') ||
            (typeDiscPrize === 'P' && 'Promoción') ||
            'NA';
          return (
            <div className={`${styles.productRow}`} key={key}>
              <div className={`${styles.productColumn}`}>{promoSequence}</div>
              <div className={`${styles.productColumn}`}>{productCode}</div>
              <div className={`${styles.productColumn}`}>{discountType}</div>
              <div className={`${styles.productColumn}`}>{initialDay}</div>
              <div className={`${styles.productColumn}`}>{finalDay}</div>
              <div className={styles.actions}>
                <div className={`${styles.action} ${styles.edit}`}>
                  <Link
                    to={`${match.path}editpromotion/${promoSequence}/${typeDiscPrize}`}
                    className={styles.edit}>
                    <i className='material-icons'>edit</i>
                  </Link>
                </div>
                <div
                  className={`${styles.action} ${styles.delete}`}
                  onClick={() => {
                    deletePrize(val);
                  }}>
                  <i className='material-icons'>delete</i>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Discounts;
