import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

//Dependencies
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

//Custom
import deptosArray from './ProductForm/deptos.js';
import subDeptosArray from './ProductForm/subdeptos.js';
import deptosArrayMX from './ProductForm/deptosMX.js';
import subDeptosArrayMX from './ProductForm/subdeptosMX.js';

const deptosArr = (process.env.REACT_APP_COUNTRY === 'MX' && deptosArrayMX) || deptosArray;
const subDeptosArr = (process.env.REACT_APP_COUNTRY === 'MX' && subDeptosArrayMX) || subDeptosArray;

const Product = (props) => {
  const { webserviceURL, setSectionName, setOptionSelected, match } = props;
  const [productList, setProductList] = useState([]);
  const [deptoSelected, setDeptoSelected] = useState('1');
  const [loading, setLoading] = useState(false);

  const getProducts = useCallback(() => {
    const url = `${webserviceURL}/getDescripciones?depto=0&subdepto=&clave=`;
    setLoading(true);
    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .set('token', localStorage.getItem('TMBtoken'))
      .then((res) => {
        if (res.body) {
          setProductList(res.body);
        } else {
          alert('ocurrió un error');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [webserviceURL]);

  const deleteProduct = (product) => {
    setLoading(true);
    let question = window.confirm('¿Eliminar producto? Esta acción no se puede deshacer');
    if (question) {
      const url = `${webserviceURL}/postDescripciones`;

      superagent
        .post(url)
        .set('Content-Type', 'application/json')
        .send({
          ...product,
          accion: 'BORRAR',
        })
        .then((res) => {
          if (res.body) {
            const { mensaje } = res.body;
            window.scrollTo(0, 0);
            alert(mensaje);
            getProducts();
          } else {
            alert('Ocurró un error, vuelva a intentarlo');
          }
        })
        .catch((err) => {
          console.log(err);
          alert('Ocurró un error');
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    setSectionName('Productos');
    setOptionSelected('products');
  }, [getProducts, setSectionName, setOptionSelected]);

  return (
    <div className={styles.productListContainer}>
      <div className={styles.buttonNewProduct}>
        <Link to={`${match.path}/editproduct/newproduct`}>
          <Button
            type='button'
            variant='contained'
            size='large'
            color='primary'
            className={`${styles.button}`}>
            Nuevo producto
          </Button>
        </Link>
      </div>
      <div className={styles.tabs}>
        {deptosArr.map((val, key) => {
          return (
            <div className={styles.tab} key={key}>
              <div
                className={`${styles.tabContent} ${styles[deptoSelected === val.value]}`}
                onClick={() => setDeptoSelected(val.value)}>
                {val.label}
              </div>
            </div>
          );
        })}
      </div>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {productList.length > 0 && (
        <div className={styles.productList}>
          <div className={`${styles.productRow} ${styles.header}`}>
            <div className={`${styles.headerColumn} ${styles.imageProduct}`}>Imagen</div>
            <div className={`${styles.headerColumn}`}>Clave</div>
            <div className={`${styles.headerColumn}`}>Nombre</div>
            <div className={`${styles.headerColumn}`}>Departamento</div>
            <div className={`${styles.headerActions}`}>Acciones</div>
          </div>
          {productList.map((val, key) => {
            const rowType = key % 2;
            const { clave, producto, departamento, subdepartamento } = val;

            const subdepto =
              (subDeptosArr[parseInt(deptoSelected) - 1] &&
                subDeptosArr[parseInt(deptoSelected) - 1][parseInt(subdepartamento) - 1] &&
                subDeptosArr[parseInt(deptoSelected) - 1][parseInt(subdepartamento) - 1].label) ||
              '';

            return (
              deptoSelected === departamento && (
                <div className={`${styles.productRow} ${styles[rowType]}`} key={key}>
                  <div className={`${styles.productColumn} ${styles.imageProduct}`}>
                    <img
                      src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products/${clave}.gif`}
                      alt=''
                    />
                  </div>
                  <div className={`${styles.productColumn}`}>{clave}</div>
                  <div className={`${styles.productColumn}`}>{producto}</div>
                  <div className={`${styles.productColumn}`}>{subdepto}</div>
                  <div className={styles.actions}>
                    <div className={`${styles.action} ${styles.edit}`}>
                      <Link to={`${match.path}/editproduct/${clave}`} className={styles.edit}>
                        <i className='material-icons'>edit</i>
                      </Link>
                    </div>
                    <div
                      className={`${styles.action} ${styles.delete}`}
                      onClick={() => deleteProduct(val)}>
                      <i className='material-icons'>delete</i>
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Product;
