import React, { useCallback, useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import superagent from 'superagent';

//Componentes
import RowMonitorPagos from './RowMonitorPagos';
import Alert from '../Alert';

//Styles
import styles from './index.module.sass';

export default function MonitorPagos(props) {
  const { rowSelected, setRowSelected, webserviceURL, token } = props;
  const [alertText, setAlertText] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataPagos, setdataPagos] = useState([]);
  const [periodo, setperiodo] = useState({
    mes: '',
    anyo: '',
  });

  const requestPagos = useCallback(() => {
    setLoading(true);
    const url = `${webserviceURL}/GetMonitorPagos`;
    superagent
      .get(url)
      .set('token', token)
      .auth(token, { type: 'bearer' })
      .then((res) => {
        const data = res.body;
        if (data.typeMessage === 'success') {
          if (data.data.length > 0) {
            setdataPagos(data.data);
            setperiodo({ mes: data.data[0].TINPERD, anyo: data.data[0].TINYEAR });
          } else {
            setAlertText('No hay Datos');
            setOpen(true);
          }
        } else {
          setAlertText('Error en recuperar datos');
          setOpen(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err, 'ERROR EN CARGAR');
        setLoading(false);
      });
  }, [token, webserviceURL]);

  useEffect(() => {
    requestPagos();
  }, [requestPagos]);

  const tableHeader = [
    'Día',
    '00:00 a.m.',
    '01:00 a.m.',
    '02:00 a.m.',
    '03:00 a.m.',
    '04:00 a.m.',
    '05:00 a.m.',
    '06:00 a.m.',
    '07:00 a.m.',
    '08:00 a.m.',
    '09:00 a.m.',
    '10:00 a.m.',
    '11:00 a.m.',
    '12:00 p.m.',
    '13:00 p.m.',
    '14:00 p.m.',
    '15:00 p.m.',
    '16:00 p.m.',
    '17:00 p.m.',
    '18:00 p.m.',
    '19:00 p.m.',
    '20:00 p.m.',
    '21:00 p.m.',
    '22:00 p.m.',
    '23:00 p.m.',
    'Total',
  ];

  const mes = (per) => {
    let mesLetter = '';

    switch (per) {
      case 1:
        mesLetter = 'Enero';
        break;
      case 2:
        mesLetter = 'Febrero';
        break;
      case 3:
        mesLetter = 'Marzo';
        break;
      case 4:
        mesLetter = 'Abril';
        break;
      case 5:
        mesLetter = 'Mayo';
        break;
      case 6:
        mesLetter = 'Junio';
        break;
      case 7:
        mesLetter = 'Julio';
        break;
      case 8:
        mesLetter = 'Agosto';
        break;
      case 9:
        mesLetter = 'Septiembre';
        break;
      case 10:
        mesLetter = 'Octubre';
        break;
      case 11:
        mesLetter = 'Noviembre';
        break;
      case 12:
        mesLetter = 'Diciembre';
        break;

      default:
        mesLetter = '';
        break;
    }
    return mesLetter;
  };

  return (
    <div className={styles.indicatorTable}>
      <Alert open={open} setOpen={setOpen} title='Mensaje del sistema' text={alertText} />
      <div className={styles.periodo}>
        Periodo: {mes(periodo.mes)} del {periodo.anyo}
      </div>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          return (
            <div
              className={val === 'Día' ? `${styles.headerItemNumber}` : `${styles.headerItem}`}
              key={key}>
              {val}
            </div>
          );
        })}
      </div>

      {!loading ? (
        <>
          {dataPagos.map((val, key) => {
            const sumaPagos =
              val.TINTPAG00 +
              val.TINTPAG01 +
              val.TINTPAG02 +
              val.TINTPAG03 +
              val.TINTPAG04 +
              val.TINTPAG05 +
              val.TINTPAG06 +
              val.TINTPAG07 +
              val.TINTPAG08 +
              val.TINTPAG09 +
              val.TINTPAG10 +
              val.TINTPAG11 +
              val.TINTPAG12 +
              val.TINTPAG13 +
              val.TINTPAG14 +
              val.TINTPAG15 +
              val.TINTPAG16 +
              val.TINTPAG17 +
              val.TINTPAG18 +
              val.TINTPAG19 +
              val.TINTPAG20 +
              val.TINTPAG21 +
              val.TINTPAG22 +
              val.TINTPAG23;

            const sumaTotal: number =
              Number(val.TINTIMP00) +
              Number(val.TINTIMP01) +
              Number(val.TINTIMP02) +
              Number(val.TINTIMP03) +
              Number(val.TINTIMP04) +
              Number(val.TINTIMP05) +
              Number(val.TINTIMP06) +
              Number(val.TINTIMP07) +
              Number(val.TINTIMP08) +
              Number(val.TINTIMP09) +
              Number(val.TINTIMP10) +
              Number(val.TINTIMP11) +
              Number(val.TINTIMP12) +
              Number(val.TINTIMP13) +
              Number(val.TINTIMP14) +
              Number(val.TINTIMP15) +
              Number(val.TINTIMP16) +
              Number(val.TINTIMP17) +
              Number(val.TINTIMP18) +
              Number(val.TINTIMP19) +
              Number(val.TINTIMP20) +
              Number(val.TINTIMP21) +
              Number(val.TINTIMP22) +
              Number(val.TINTIMP23);

            const order = [
              val.TINDIA,
              val.TINTPAG00,
              val.TINTIMP00,
              val.TINTPAG01,
              val.TINTIMP01,
              val.TINTPAG02,
              val.TINTIMP02,
              val.TINTPAG03,
              val.TINTIMP03,
              val.TINTPAG04,
              val.TINTIMP04,
              val.TINTPAG05,
              val.TINTIMP05,
              val.TINTPAG06,
              val.TINTIMP06,
              val.TINTPAG07,
              val.TINTIMP07,
              val.TINTPAG08,
              val.TINTIMP08,
              val.TINTPAG09,
              val.TINTIMP09,
              val.TINTPAG10,
              val.TINTIMP10,
              val.TINTPAG11,
              val.TINTIMP11,
              val.TINTPAG12,
              val.TINTIMP12,
              val.TINTPAG13,
              val.TINTIMP13,
              val.TINTPAG14,
              val.TINTIMP14,
              val.TINTPAG15,
              val.TINTIMP15,
              val.TINTPAG16,
              val.TINTIMP16,
              val.TINTPAG17,
              val.TINTIMP17,
              val.TINTPAG18,
              val.TINTIMP18,
              val.TINTPAG19,
              val.TINTIMP19,
              val.TINTPAG20,
              val.TINTIMP20,
              val.TINTPAG21,
              val.TINTIMP21,
              val.TINTPAG22,
              val.TINTIMP22,
              val.TINTPAG23,
              val.TINTIMP23,
              sumaPagos,
              sumaTotal.toFixed(2),
            ];
            return (
              <RowMonitorPagos
                key={key}
                order={order}
                fullwidth={true}
                rowSelected={rowSelected}
                setRowSelected={setRowSelected}
              />
            );
          })}
        </>
      ) : (
        <Box>
          <Skeleton variant='rounded' height={30} className={styles.loadingText} />
          <Skeleton variant='rounded' height={30} className={styles.loadingText} />
          <Skeleton variant='rounded' height={30} className={styles.loadingText} />
        </Box>
      )}
    </div>
  );
}
