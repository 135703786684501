import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.sass';
import superagent from 'superagent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

const EnableROP = (props) => {
  const { webserviceURL, setOptionSelected, setSectionName } = props;
  const [message, setmessage] = useState('');
  const [actualState, changeCheckState] = useState(false);
  const [loading, setloading] = useState(false);

  const Activated = useCallback(() => {
    setloading(true);
    superagent
      .get(`${webserviceURL}/RopHabilitado?accion=CONSULTAR`)
      .then((res) => {
        const { body = {} } = res;
        if (body.typeMessage === 'success') {
          if (body.message === 'S') {
            changeCheckState(true);
          } else {
            changeCheckState(false);
          }
        } else {
          alert(body.message);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  }, [webserviceURL]);

  const handleChexbox = (e) => {
    let act = '';
    changeCheckState(e.target.checked);
    setloading(true);

    if (actualState === false) {
      act = 'S';
    } else {
      act = 'N';
    }

    superagent
      .get(`${webserviceURL}/RopHabilitado?accion=ESTABLECER&valorSN=${act}`)
      .then((res) => {
        const { body = {} } = res;
        if (body.typeMessage === 'success') {
          switch (body.message) {
            case 'Valor establecido como S':
              setmessage('El formulario de registro se activo');
              break;
            case 'Valor establecido como N':
              setmessage('El formulario de registro cambio a inactivo');
              break;
            default:
              break;
          }
          setloading(false);
          setOpen(true);
        } else {
          alert(body.message);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setOptionSelected('rop');
    setSectionName('Formulario ROP');
    Activated();
  }, [Activated, setOptionSelected, setSectionName]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={styles.title}>Administración de Formulario (ROP)</div>
      <div className={styles.form}>
        <div className={styles.onoffswitch}>
          <input
            type='checkbox'
            name='onoffswitch'
            className={styles.onoffswitchcheckbox}
            id='myonoffswitch'
            onChange={handleChexbox}
            checked={actualState}
          />
          <label className={styles.onoffswitchlabel} htmlFor='myonoffswitch'>
            <div className={styles.onoffswitchinner}></div>
            <div className={styles.onoffswitchswitch}></div>
          </label>
        </div>
      </div>
      <div>{loading && <LinearProgress color='primary' className={styles.progress} />}</div>
      <div className={styles.message}>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{'Mensaje del sistema'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default EnableROP;
