import React from 'react';
import styles from './index.module.sass';

const RowMonitorPagos = (props) => {
  const { order, fullwidth } = props;

  const renderTable = () => {
    const pairs = [];

    // Mostrar el primer campo solo una vez
    if (order.length > 0) {
      const firstValue = order[0];
      const firstDiv = (
        <div className={`${styles.rowItemFirst} ${styles[fullwidth]}`} key={0}>
          {firstValue}
        </div>
      );
      pairs.push(firstDiv);
    }

    // Mostrar los campos restantes de dos en dos
    for (let i = 1; i < order.length; i += 2) {
      const val1 = order[i];
      const val2 = i + 1 < order.length ? order[i + 1] : null;

      const pair = (
        <div className={`${styles.rowItem} ${styles[fullwidth]}`} key={i}>
          <div>
            <span className={styles.cantidad}>{val1}</span>{' '}
            <span className={styles.pago}>pagos</span>
          </div>
          <div className={styles.monto}>
            {val2 !== 'Total' && '$'}
            {val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
      );

      pairs.push(pair);
    }

    return pairs;
  };

  return <div className={styles.tableRow}>{renderTable()}</div>;
};

export default RowMonitorPagos;
