import React, { useState } from 'react';
import styles from '../index.module.sass';

//Dependencies
import superagent from 'superagent';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Catalog = (props) => {
  const {
    getCatalog,
    getCookie,
    year,
    month,
    match,
    loading,
    catalog,
    categories,
    setLoading,
    webserviceURL,
  } = props;

  const [deptoSelected, setDeptoSelected] = useState('1000');
  const [subCat, setSubcatSelected] = useState('1005');

  const onChangeDepto = (cat) => {
    categories.map((val) => {
      if (val.code === cat) {
        setSubcatSelected(val.subcateg[0].number);
      }
      return null;
    });
    setDeptoSelected(cat);
  };

  const deleteProduct = (product) => {
    setLoading(true);

    const yearSelected = getCookie('yearSelected') || year;
    const monthSelected = getCookie('monthSelected') || month;

    let question = window.confirm('¿Eliminar producto?');
    if (question) {
      let jsonBody = Object.assign({}, product);
      jsonBody.yearMonth = `${yearSelected}${monthSelected}`;
      jsonBody.status = 'B';
      delete jsonBody.category;
      const url = `${webserviceURL}/postCatalog`;

      superagent
        .post(url)
        .set('Content-Type', 'application/json')
        .auth(
          localStorage.getItem('TMBtoken'),
          (process.env.REACT_APP_COUNTRY === 'MX' && {}) || { type: 'bearer' },
        )
        .send(jsonBody)
        .then((res) => {
          if (res.body) {
            const { body = {} } = res;
            const { message = '' } = body;
            window.scrollTo(0, 0);
            alert(message);
            getCatalog();
          } else {
            alert('An error occurred, please try again');
          }
        })
        .catch((err) => {
          console.log(err);
          alert('An error occurred');
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <div id={styles.Catalog}>
      {(getCookie('yearSelected') > year ||
        (getCookie('yearSelected') >= year && getCookie('monthSelected') >= month)) && (
        <div className={styles.buttonNewProduct}>
          <Link to={`${match.path}/editproduct/newproduct`}>
            <Button
              type='button'
              variant='contained'
              size='large'
              color='primary'
              className={`${styles.button}`}>
              Nuevo producto
            </Button>
          </Link>
        </div>
      )}

      <div className={styles.tabs}>
        {categories.map((val, key) => {
          if (val.code !== '')
            return (
              <div className={styles.tab} key={key}>
                <div
                  className={`${styles.tabContent} ${styles[deptoSelected === val.code]}`}
                  onClick={() => onChangeDepto(val.code)}>
                  {val.descr}
                </div>
              </div>
            );
          return null;
        })}
      </div>

      <div className={styles.subcatContainer}>
        {categories.map((val, key) => {
          let subs = [];
          if (val.code === deptoSelected && val.subcateg.length > 0) {
            subs = val.subcateg.map((subval, subkey) => {
              if (subval.number !== '') {
                return (
                  <div
                    key={subkey}
                    className={`${styles.subCat} ${styles[subCat === subval.number]}`}
                    onClick={() => setSubcatSelected(subval.number)}>
                    <span>{subval.descri}</span>
                  </div>
                );
              }
              return null;
            });
          }
          return subs;
        })}
      </div>

      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}

      {catalog.length > 0 && (
        <div className={styles.productList}>
          <div className={`${styles.productRow} ${styles.header}`}>
            <div className={`${styles.headerColumn} ${styles.imageProduct}`}>Imagen</div>
            <div className={`${styles.headerColumn}`}>Clave</div>
            <div className={`${styles.headerColumn}`}>Nombre</div>
            <div className={`${styles.headerColumn}`}>Precio de lista</div>
            <div className={`${styles.headerColumn}`}>Descuento</div>
            <div className={`${styles.headerColumn}`}>Precio Descuento</div>
            <div className={`${styles.headerActions}`}>Acciones</div>
          </div>
          {catalog.map((val, key) => {
            if (val.code !== '') {
              const rowType = key % 2;
              const {
                product,
                description,
                promotSubcat,
                baseSubcateg,
                listPrice,
                discount,
                promotPrice,
              } = val;
              return (
                ((baseSubcateg === subCat && promotSubcat !== '6150') ||
                  (subCat === '6150' && promotSubcat === '6150')) && (
                  <div className={`${styles.productRow} ${styles[rowType]}`} key={key}>
                    <div className={`${styles.productColumn} ${styles.imageProduct}`}>
                      <img
                        src={`https://webimages.terramarbrands.com.mx/shopping-cart/cart-products/${product}.gif`}
                        alt=''
                      />
                    </div>
                    <div className={`${styles.productColumn}`}>{product}</div>
                    <div className={`${styles.productColumn}`}>{description}</div>
                    <div className={`${styles.productColumn}`}>{parseFloat(listPrice)}</div>
                    <div className={`${styles.productColumn}`}>{discount}</div>
                    <div className={`${styles.productColumn}`}>{parseFloat(promotPrice)}</div>
                    <div className={styles.actions}>
                      <div className={`${styles.action} ${styles.edit}`}>
                        <Link to={`${match.path}/editproduct/${product}`} className={styles.edit}>
                          {(getCookie('yearSelected') <= year &&
                            getCookie('monthSelected') < month && (
                              <i className='material-icons'>visibility</i>
                            )) || <i className='material-icons'>edit</i>}
                        </Link>
                      </div>
                      <div
                        className={`${styles.action} ${styles.delete}`}
                        onClick={() => {
                          deleteProduct(val);
                        }}>
                        <i className='material-icons'>delete</i>
                      </div>
                    </div>
                  </div>
                )
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default Catalog;
