const deptos = [
  {
    value: '1',
    label: 'Color',
  },
  {
    value: '2',
    label: 'Fragancias',
  },
  {
    value: '3',
    label: 'Cuidado capilar',
  },
  {
    value: '4',
    label: 'Cuidado de la barba',
  },
  {
    value: '5',
    label: 'Cuidado de la piel',
  },
  {
    value: '6',
    label: 'Cuerpo',
  },
  {
    value: '7',
    label: 'Antitranspirantes',
  },
  {
    value: '8',
    label: 'Higiene intima',
  },
  {
    value: '9',
    label: 'Sanitizantes',
  },
];

export default deptos;
