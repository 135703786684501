import React, { useState, useEffect, useCallback } from 'react';
import styles from './index.module.sass';

const InputFly = (props) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [labelWidth, setLabelWidth] = useState(109);

  const {
    focusedBorderColor = '#002747',
    focusedLabel = '#002747',
    placeholder = 'Text',
    value = '',
    handleChange = null,
    onBlur = null,
    name,
    type,
    shrink,
    maxlength,
    error,
  } = props;

  const getTextWidth = useCallback(() => {
    let text = document.createElement('span');
    document.body.appendChild(text);

    text.style.font = 'times new roman';
    text.style.fontSize = 12 + 'px';
    text.style.height = 'auto';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.innerHTML = placeholder;

    const width = Math.ceil(text.clientWidth);
    const formattedWidth = width;
    setLabelWidth(formattedWidth + 8);

    document.body.removeChild(text);
  }, [placeholder]);

  useEffect(() => {
    getTextWidth();
  }, [getTextWidth]);

  return (
    <div id={styles.InputFly}>
      <label
        className={`${styles.formLabel} ${styles[inputFocus || value !== '' || shrink]}`}
        style={{ color: (error && 'red') || (inputFocus && focusedLabel) }}>
        {placeholder}
      </label>
      <div className={styles.FlyInputBase}>
        <fieldset
          className={styles.FlyFieldset}
          style={{
            paddingLeft: ((inputFocus || value !== '' || shrink) && '8px') || `${labelWidth / 2}px`,
            borderColor: (error && 'red') || (inputFocus && focusedBorderColor) || '#000',
            borderWidth: (inputFocus && '2px') || '1px',
          }}>
          <legend
            className={styles.FlyLegend}
            style={{
              width: ((inputFocus || value !== '' || shrink) && `${labelWidth}px`) || '0.01px',
            }}>
            &#8203;
          </legend>
        </fieldset>
        <input
          className={styles.FlyInput}
          onFocus={() => setInputFocus(true)}
          onBlur={(e) => {
            setInputFocus(false);
            if (onBlur) onBlur(e);
          }}
          value={value}
          name={name}
          onChange={(e) => handleChange(e)}
          type={type || 'text'}
          maxLength={maxlength || null}
        />
      </div>
    </div>
  );
};

export default InputFly;
