import React, { useEffect, useCallback, useState } from 'react';
import styles from '../index.module.sass';

//Dependencies
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

//Constants
import months from '../months';

const date = new Date();
const year = date.getFullYear();
const month = ("0" + (date.getMonth() + 1)).slice(-2);

const PromoForm = (props) => {
    const { match, getCookie, history, webserviceURL } = props;
    const { params = {} } = match;
    const { promoSequence = '', typeDiscPrize = ''} = params;
    const [loading, setLoading] = useState(false);
    
    const [promotion, setPromotion] = useState({
        promoSequence: "",
        startHour: '00',
        startMinutes: '00',
        startSeconds: '00',
        endHour: '00',
        endMinutes: '00',
        endSeconds: '00',
        initialDay: 1,
        finalDay: 1,
        startTime: 0,
        endTime: 0,
        enableForIncome: "0",
        enableForActive: "0",
        enableReactive: "0",
        enableWithhold: "0",
        enableWelcome: "0",
        productCode: '',
        inventoryCode: '',
        giftUnits: 1,
        moduleIncome: 1,
        moduleActive: 1,
        moduleReactive: 1,
        moduleWithold: 1,
        moduleWelcome: 1,
        discPercHalfM: 0,
        discOneModule: 0,
        discTwoModule: 0,
        discThreeMod: 0,
        giftRegular: "O",
        promoDesc: "",
        promoId: "",
        productDesc: "",
        inventoryDesc: "",
        typeDiscPrize: "P"
    });

    const yearSelected = getCookie('yearSelected') || year;
    const monthSelected = getCookie('monthSelected') || month;
    const daysInMonth = new Date(yearSelected, monthSelected, 0).getDate();

    const getSinglePromo = useCallback((props) => {
        if(promoSequence === 'newprize') return;
        setLoading(true);
        const promoSequenceScape = (promoSequence.length <= 1 && `0${promoSequence}`) || promoSequence;
        const url = `http://intranettb.dyndns.org:10000/web/services/getPromotion1?yearMonth=${yearSelected}${monthSelected}&promoSequence=${promoSequenceScape}&typeDiscPrize=${typeDiscPrize}`;

        superagent
            .get(url)
            .then((res) => {
                const { body = {} } = res;
                const { typeMessage = 'error', message= '', getPromovresult = {} } = body;
                
                const startTime = (getPromovresult.startTime && getPromovresult.startTime.toString()) || '000000';
                const endTime = (getPromovresult.endTime && getPromovresult.endTime.toString()) || '235955';
                
                const startHour = startTime.substr(0, 2) || '00';
                const startMinutes = startTime.substr(2, 2) || '00';
                const startSeconds = startTime.substr(4, 2) || '00';
                const endHour = endTime.toString().substr(0, 2) || '00';
                const endMinutes = endTime.toString().substr(2, 2) || '00';
                const endSeconds = endTime.toString().substr(4, 2) || '00';
                
                if(typeMessage !== 'error'){
                    setPromotion({
                        ...getPromovresult,
                        typeDiscPrize,
                        startHour,
                        startMinutes,
                        startSeconds,
                        endHour,
                        endMinutes,
                        endSeconds,
                        promoSequence: promoSequenceScape
                    });
                }else {
                    alert(message);
                    setPromotion({});
                }
                setLoading(false);
            })
            .catch((err) => {
              alert('ERROR EN LOADSHEET PROMOFORM');
              console.log(err, 'ERROR EN LOADSHEET PROMOFORM');
              setLoading(false);
            });
    }, [promoSequence, typeDiscPrize, yearSelected, monthSelected]);

    const handleChange = (e) => {
        let value = e.target.value;
        const name = e.target.name;
    
        setPromotion({
          ...promotion,
          [name]: value,
        });
    };

    const daysInM = () => {
        const days = [];
        
        for(var i=1; i<=daysInMonth; i++){
            days.push(
                <MenuItem value={i} key={`month-${i}`}>
                    {i}
                </MenuItem>
            );
        }

        return days;
    };

    const onSubmit = () => {
        if(promotion.promoSequence.trim() === ''){
            alert('You must put a sequence');
            return;
        }
        setLoading(true);
        let jsonBody = Object.assign({}, promotion);
        
        jsonBody.startTime = `${jsonBody.startHour}${jsonBody.startMinutes}${jsonBody.startSeconds}`;
        jsonBody.endTime = `${jsonBody.endHour}${jsonBody.endMinutes}${jsonBody.endSeconds}`;
        //jsonBody.startTime = parseInt(jsonBody.startTime);
        //jsonBody.endTime = parseInt(jsonBody.endTime);
        delete jsonBody.startHour;
        delete jsonBody.startMinutes;
        delete jsonBody.startSeconds;
        delete jsonBody.endHour;
        delete jsonBody.endMinutes;
        delete jsonBody.endSeconds;
        
        jsonBody.typeRecord = (match.params && match.params.promoSequence === 'newprize' && 'A') || 'M';
        jsonBody.yearMonth = `${getCookie('yearSelected')}${getCookie('monthSelected')}`;

        jsonBody.discPercHalfM = parseFloat(jsonBody.discPercHalfM);
        jsonBody.discOneModule = parseFloat(jsonBody.discOneModule);
        jsonBody.discTwoModule = parseFloat(jsonBody.discTwoModule);
        jsonBody.discThreeMod = parseFloat(jsonBody.discThreeMod);

        //const url = `${webserviceURL}/postCatalog`;
        const url = `http://intranettb.dyndns.org:10000/web/services/postPromo`;

        superagent
        .post(url)
        .set('Content-Type', 'application/json')
        .send(jsonBody)
        .then((res) => {
            if (res.body) {
                const {body = {} } = res;
                
                const { message = '' } = body;
                window.scrollTo(0, 0);
                alert(message);
            } else {
                alert('An error occurred, please try again');
            }
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            alert('An error occurred');
            setLoading(false);
        });
    };

    const onBlur = (e, type) => {
        const name = e.target.name;
        let value = e.target.value;
        
        if(parseInt(value) > 59) value = 59;
        if(type === 'hour' && parseInt(value) > 23) value = '00';
        if(value.toString().length < 1) value = `00`;
        if(value.toString().length < 2) value = `0${value}`;
        

        setPromotion({
            ...promotion,
            [name]: value,
        });

    };

    const searchProduct = (e) => {
        setLoading(true);
        const value = e.target.value;
        const yearSelected = getCookie('yearSelected') || year;
        const monthSelected = getCookie('monthSelected') || month;

        const url = `${webserviceURL}/getCatalog1?yearMonth=${yearSelected}${monthSelected}&product=${value}`;
      
        superagent
            .get(url)
            .set('Content-Type', 'application/json')
            .then((res) => {
                const { body = {} } = res;
                const { getCatvresult = {}, message = "", typeMessage = "error" } = body;
                if (typeMessage !== 'error') {
                   
                    const { description = '', additDescrip = '', optInvDown = '' } = getCatvresult;
                    setPromotion({
                        ...promotion,
                        productDesc: description,
                        inventoryDesc: additDescrip,
                        inventoryCode: optInvDown
                    });
                } else {
                    alert(message);
                    console.log('THE PRODUCT DOES NOT EXIST');
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                console.log('AN ERROR OCCURRED WHEN TRYING TO OBTAIN THE PRODUCT');
                setLoading(false);
            });
    };

    useEffect(() => {
        getSinglePromo();
    }, [getSinglePromo]);

    return <div id={styles.PromoForm} className={styles.productFormContainer}>
        <div>
            <span className={styles.link} onClick={() => history.goBack()}>
                Regresar
            </span>
        </div>

        <div className={styles.catDate}>
            <span className={styles.text}>Año:</span><span>{yearSelected}</span>
            <span className={styles.text}>Mes:</span><span>{months[parseInt(monthSelected) - 1]}</span>
        </div>

        {(promoSequence !== 'newprize' && <div className={styles.catDate}>
            <span className={styles.text}>Tipo:</span><span>{(promotion.typeDiscPrize === 'P' && 'Premio') || 'Descuento'}</span>
            <span className={styles.text}>Secuencia:</span><span>{promotion.promoSequence}</span>
        </div>) || 
        <div className={styles.inputsContainer}>       
            <div className={`${styles.five} ${styles.first}`}>  
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                    label='Tipo'
                    value={promotion.typeDiscPrize}
                    onChange={handleChange}
                    name='typeDiscPrize'>
                        <MenuItem value='P'>
                            Premio
                        </MenuItem>
                        <MenuItem value='D'>
                            Descuento
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={`${styles.five}`}>
                <TextField
                    onChange={handleChange}
                    name='promoSequence'
                    label='Secuencia'
                    type='number'
                    value={promotion.promoSequence}
                    onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                    }}
                    variant='outlined'
                    fullWidth
                />
            </div>
        </div>}

        <div className={styles.inputsContainer}>
            <div className={`${styles.four} ${styles.first}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Día de inicio</InputLabel>
                    <Select
                    label='Día de inicio'
                    value={promotion.initialDay}
                    onChange={handleChange}
                    name='initialDay'>
                        {
                            daysInM()
                        }
                    </Select>
                </FormControl>
            </div>

            <div className={styles.innerInputsContainer}>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e, 'hour')}}
                        name='startHour'
                        label='Hora de inicio (en 24hr)'
                        type='number'
                        value={promotion.startHour}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e)}}
                        name='startMinutes'
                        label='Minutos inicio'
                        type='number'
                        value={promotion.startMinutes}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e)}}
                        name='startSeconds'
                        label='segundos inicio'
                        type='number'
                        value={promotion.startSeconds}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
            </div>

            <div className={`${styles.four}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Día de fin</InputLabel>
                    <Select
                    label='Día de fin'
                    value={promotion.finalDay}
                    onChange={handleChange}
                    name='finalDay'>
                        {
                            daysInM()
                        }
                    </Select>
                </FormControl>
            </div>

            <div className={styles.innerInputsContainer}>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e, 'hour')}}
                        name='endHour'
                        label='Hora de fin (en 24hr)'
                        type='number'
                        value={promotion.endHour}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e)}}
                        name='endMinutes'
                        label='Munutos de fin'
                        type='number'
                        value={promotion.endMinutes}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={(e) => {onBlur(e)}}
                        name='endSeconds'
                        label='segundos de fin'
                        type='number'
                        value={promotion.endSeconds}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                        }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
            </div>
        </div>

        <div className={styles.title}>ACTIVO PARA...</div>
        <div className={styles.inputsContainer}>
            <div className={`${styles.five} ${styles.first}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Nuevos ingresos</InputLabel>
                    <Select
                        label='Nuevos ingresos'
                        value={promotion.enableForIncome}
                        onChange={handleChange}
                        name='enableForIncome'>
                            <MenuItem value='1'>
                                Si
                            </MenuItem>
                            <MenuItem value='0'>
                                No
                            </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={`${styles.five}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Activas-Existen</InputLabel>
                    <Select
                        label='Activas-Existen'
                        value={promotion.enableForActive}
                        onChange={handleChange}
                        name='enableForActive'>
                            <MenuItem value='1'>
                                Si
                            </MenuItem>
                            <MenuItem value='0'>
                                No
                            </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={`${styles.five}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Reactivaciones</InputLabel>
                    <Select
                        label='Reactivaciones'
                        value={promotion.enableReactive}
                        onChange={handleChange}
                        name='enableReactive'>
                            <MenuItem value='1'>
                                Si
                            </MenuItem>
                            <MenuItem value='0'>
                                No
                            </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={`${styles.five}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Retenciones</InputLabel>
                    <Select
                        label='Retenciones'
                        value={promotion.enableWithhold}
                        onChange={handleChange}
                        name='enableWithhold'>
                            <MenuItem value='1'>
                                Si
                            </MenuItem>
                            <MenuItem value='0'>
                                No
                            </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={`${styles.five}`}>
                <FormControl variant='outlined' fullWidth>
                    <InputLabel>Bienvenida</InputLabel>
                    <Select
                        label='Bienvenida'
                        value={promotion.enableWelcome}
                        onChange={handleChange}
                        name='enableWelcome'>
                            <MenuItem value='1'>
                                Si
                            </MenuItem>
                            <MenuItem value='0'>
                                No
                            </MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>

        {promotion.typeDiscPrize === 'P' && <> 
            <div className={styles.inputsContainer}>
                <div className={`${styles.three} ${styles.first}`}>
                    <TextField
                        onChange={handleChange}
                        onBlur={searchProduct}
                        name='productCode'
                        label='Clave de producto'
                        type='text'
                        value={promotion.productCode}
                        inputProps={{ maxLength: 5 }}
                        variant='outlined'
                        fullWidth
                        disabled={promoSequence !== 'newprize' && true}
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        name='inventoryCode'
                        label='Clave de inventario'
                        type='text'
                        value={promotion.inventoryCode}
                        inputProps={{ maxLength: 5 }}
                        variant='outlined'
                        fullWidth
                        disabled={true}
                    />
                </div>
                <div className={`${styles.three}`}>
                    <TextField
                        onChange={handleChange}
                        name='giftUnits'
                        label='Unidades a regalar'
                        type='text'
                        value={promotion.giftUnits}
                        inputProps={{ maxLength: 5 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
            </div>

            <div className={styles.inputsContainer}>
                <div className={`${styles.four} ${styles.first}`}>
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel>Regalo</InputLabel>
                        <Select
                        label='Regalo'
                        value={promotion.giftRegular}
                        onChange={handleChange}
                        name='giftRegular'>
                            <MenuItem value='O'>
                                Obsequio
                            </MenuItem>
                            <MenuItem value='R'>
                                Regular
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='promoDesc'
                        label='Descripción de promoción'
                        type='text'
                        value={promotion.promoDesc}
                        inputProps={{ maxLength: 35 }}
                        variant='outlined'
                        fullWidth
                    /> 
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='productDesc'
                        label='Descripción de producto'
                        type='text'
                        value={promotion.productDesc}
                        inputProps={{ maxLength: 30 }}
                        variant='outlined'
                        disabled={true}
                        fullWidth
                    /> 
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='inventoryDesc'
                        label='Descripción de inventario'
                        type='text'
                        value={promotion.inventoryDesc}
                        inputProps={{ maxLength: 30 }}
                        variant='outlined'
                        disabled={true}
                        fullWidth
                    /> 
                </div>
            </div>
            
            <div className={styles.title}>MODULOS REQUERIDOS</div>
            <div className={styles.inputsContainer}>
                <div className={`${styles.five} ${styles.first}`}>
                    <TextField
                        onChange={handleChange}
                        name='moduleIncome'
                        label='Nuevos ingresos'
                        type='number'
                        value={promotion.moduleIncome}
                        inputProps={{ maxLength: 3 }}
                        variant='outlined'
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                        }}
                        fullWidth
                    />
                </div>
                <div className={`${styles.five}`}>
                    <TextField
                        onChange={handleChange}
                        name='moduleActive'
                        label='Activas-Existen'
                        type='number'
                        value={promotion.moduleActive}
                        inputProps={{ maxLength: 3 }}
                        variant='outlined'
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                        }}
                        fullWidth
                    /> 
                </div>
                <div className={`${styles.five}`}>
                    <TextField
                        onChange={handleChange}
                        name='moduleReactive'
                        label='Reactivaciones'
                        type='number'
                        value={promotion.moduleReactive}
                        inputProps={{ maxLength: 3 }}
                        variant='outlined'
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                        }}
                        fullWidth
                    />
                </div>
                <div className={`${styles.five}`}>
                    <TextField
                        onChange={handleChange}
                        name='moduleWithold'
                        label='Retenciones'
                        type='number'
                        value={promotion.moduleWithold}
                        inputProps={{ maxLength: 3 }}
                        variant='outlined'
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                        }}
                        fullWidth
                    />
                </div>
                <div className={`${styles.five}`}>
                    <TextField
                        onChange={handleChange}
                        name='moduleWelcome'
                        label='Bienvenida'
                        type='text'
                        value={promotion.moduleWelcome}
                        inputProps={{ maxLength: 3 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
            </div>
        </>}

        {
            promotion.typeDiscPrize === 'D' && <div className={styles.inputsContainer}>
                <div className={`${styles.four} ${styles.first}`}>
                    <TextField
                        onChange={handleChange}
                        name='discPercHalfM'
                        label='% descuento 1/2 modulo'
                        type='text'
                        value={promotion.discPercHalfM}
                        inputProps={{ maxLength: 4 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='discOneModule'
                        label='% descuento 1 modulo'
                        type='text'
                        value={promotion.discOneModule}
                        inputProps={{ maxLength: 4 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='discTwoModule'
                        label='% descuento 2 modulos'
                        type='text'
                        value={promotion.discTwoModule}
                        inputProps={{ maxLength: 4 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
                <div className={`${styles.four}`}>
                    <TextField
                        onChange={handleChange}
                        name='discThreeMod'
                        label='% descuento 3 modulos'
                        type='text'
                        value={promotion.discThreeMod}
                        inputProps={{ maxLength: 4 }}
                        variant='outlined'
                        fullWidth
                    />
                </div>
            </div>
        }

        <div className={styles.buttonsContainer}>
            {(loading && <div className={styles.loading}>
                <CircularProgress />
            </div>) || <Button
                type='button'
                variant='contained'
                size='large'
                color='primary'
                className={`${styles.button}`}
                onClick={(e) => {
                    onSubmit(e);
                }}>
                Guardar
            </Button>}
        </div>

    </div>
};

export default PromoForm;