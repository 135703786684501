import React, { useEffect, useState, useCallback } from 'react';
import styles from './index.module.sass';
import superagent from 'superagent';
import CircularProgress from '@material-ui/core/CircularProgress';

const Convertation = (props) => {
  const { screenSize, rowSelected, webserviceURL, utf8Decode, loading, token } = props;
  const [conversation, setConversation] = useState([]);

  const getConversation = useCallback(() => {
    //setLoading(true);
    let url = `${webserviceURL}/getAtencionComentarios`;
    url += '?folio=' + rowSelected.folio;

    superagent
      .get(url)
      .set('Content-Type', 'application/json')
      .set('token', token)
      .auth(token, { type: 'bearer' })
      .then((res) => {
        const { body = {} } = res;
        const { comentarios = [] } = body;
        setConversation(comentarios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [rowSelected, webserviceURL, token]);

  useEffect(() => {
    if (!token) return;
    getConversation();
  }, [getConversation, loading, token]);

  let splitDate = rowSelected.fechaCaptura.split('-');
  const firsyear = splitDate[0];
  const firstmonth = splitDate[1];
  const firstday = splitDate[2];

  const firstDate = firstday + '/' + firstmonth + '/' + firsyear;

  return (
    (loading && <CircularProgress className={styles.circularProgress} />) || (
      <div className={`${styles.atentionDesc} ${styles[screenSize]}`}>
        <div id={styles.Chat}>
          <div className={styles.youballoon}>
            <div className={styles.chatText}>
              <div className={styles.date}>{`${firstDate} kit: ${rowSelected.kit}`}</div>
              {utf8Decode(rowSelected.descripcion)}
            </div>
          </div>
          {conversation.map((val, key) => {
            return (
              <div
                className={`${(val.tipo === '0' && styles.youballoon) || styles.iballoon}`}
                key={key}>
                <div className={styles.chatText}>
                  <div className={styles.date}>
                    {val.fecha + ' ' + val.hora + ' Kit: ' + val.kit}
                  </div>
                  {utf8Decode(val.descripcion)}
                  <br />
                  {val.imagen !== '' ? (
                    <a href={val.imagen} target='_blank' rel='noopener noreferrer'>
                      <img
                        className={styles.ballonImage}
                        src={val.imagen}
                        onClick={() => {
                          window.open(val.imagen);
                        }}
                        alt=''
                      />
                    </a>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default Convertation;
