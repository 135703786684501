import React from 'react';
import styles from './index.module.sass';

const TableRow = (props) => {
  const { order, fullwidth, rowSelected, setRowSelected, row } = props;
  const HandleClick = (row) => {
    setRowSelected(row);
  };

  return (
    <div
      className={`${styles.tableRow} ${rowSelected === row && styles.selected}`}
      onClick={(e) => HandleClick(row)}>
      {order.map((val, key) => {
        return (
          <div
            className={`${styles.rowItem} ${styles[fullwidth]} ${
              rowSelected === key && styles.selected
            }`}
            key={key}>
            {val}
          </div>
        );
      })}
    </div>
  );
};

export default TableRow;
