import webservice from './webservice';

export default async function postTerrapediaArticle(accion, depto, theme) {
  const url = `${webservice}/postTerrapediaArticle`;

  let response = {};

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('TMBtoken')}`,
    },
    body: JSON.stringify({
      Action: accion,
      Department: depto,
      Theme: theme,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      response = res;
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        error: err,
        tipoMensaje: 'error',
        mensaje: 'Error',
      };
      response = resp;
    });

  return response;
}
