import superagent from 'superagent';
import webservice from './webservice';

export default async function postsurvey({ json }) {
  const url = `${webservice}/survey`;

  let response = {};

  await superagent
    .post(url)
    .set('Content-Type', 'application/json')
    .set('token', localStorage.getItem('TMBtoken'))
    .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
    .send(json)
    .then((res) => {
      response = res.body;
    })
    .catch((err) => {
      console.log(err);
      const resp = {
        error: err,
        TypeMessage: 'error',
        Message: 'Error',
      };
      response = resp;
    });

  return response;
}
