import React from 'react';
import styles from './index.module.sass';

import TableRow from './TableRow';

const Kois = (props) => {
  const { indicatorArray, rowSelected, setRowSelected } = props;
  const tableHeader = ['Año', 'Mes', 'Tipo', 'Total'];

  return (
    <div className={styles.indicatorTable}>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          return (
            <div className={`${styles.headerItem} ${styles.fullwidth}`} key={key}>
              {val}
            </div>
          );
        })}
      </div>
      {indicatorArray.map((val, key) => {
        const order = [val.ANO, val.MES, val.TIPO, val.TOTAL];
        return (
          <TableRow
            key={key}
            row={key}
            order={order}
            fullwidth={true}
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
          />
        );
      })}
    </div>
  );
};

export default Kois;
