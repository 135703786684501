import React, { useState, useEffect } from 'react';

//Components
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../Alert';

//Dependencias
import superagent from 'superagent';

//Styles
import styles from './index.module.sass';

export default function DeleteSiVale(props) {
  const { screenSize, webserviceURL } = props;
  const [loading, setloading] = useState(false);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [valuesForm, setvaluesForm] = useState({
    card: '',
    iut: '',
    kit: '',
  });

  const [msjErr, setmsjErr] = useState({
    msjErrInput1: '',
    msjErrInput2: '',
  });

  function changeValue(e, type) {
    let value = e.target.value;

    switch (e.target.name) {
      case 'card':
        value = value.replace(
          /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}abcdefghijklmnopqrstuvwxyz[\]\\/ ]/gi,
          '',
        );

        if (value.length < 16) {
          setmsjErr({
            ...msjErr,
            msjErrInput1: 'Datos incorrectos, verifica el numero de tarjeta deben ser 16 digitos',
          });
        } else {
          setmsjErr({ ...msjErr, msjErrInput1: '' });
        }

        break;

      case 'iut':
        value = value.replace(
          /[`~´!¡@#$%^&*()_|+\-=¿?;:'",.<>œæ€®†¥øπå∫∂ƒ™¶§≤∑©√ßµ„…–¢∞¬÷“”≠‚ºª·ç¨Ωñ{}[\]\\/ ]/gi,
          '',
        );
        if (value.length < 12) {
          setmsjErr({
            ...msjErr,
            msjErrInput2: 'Datos incorrectos, verifica el numero IUT deben ser 12 digitos',
          });
        } else {
          setmsjErr({ ...msjErr, msjErrInput2: '' });
        }
        break;

      default:
        break;
    }

    setvaluesForm({
      ...valuesForm,
      [e.target.name]: value,
    });
  }

  async function handleSubmit() {
    setbuttonDisabled(false);
    const url = `${webserviceURL}/DeleteSiVale`;

    let params = {
      numTarjeta: valuesForm.card,
      iut: valuesForm.iut,
      Kit: valuesForm.kit,
    };

    superagent
      .delete(url)
      .set('Content-Type', 'application/json')
      .set('token', localStorage.getItem('TMBtoken'))
      .auth(localStorage.getItem('TMBtoken'), { type: 'bearer' })
      .send(params)
      .then((res) => {
        if (res.body.typeMessage === 'error') {
          setAlertText('Ocurrio un error, revista la información capturada e intentalo de nuevo');
          setOpen(true);
        } else {
          if (res.body.message === 'Ok') {
            setAlertText('Baja exitosa');
            setvaluesForm({
              card: '',
              iut: '',
              kit: '',
            });

            setOpen(true);
          } else {
            setAlertText(res.body.message);
            setOpen(true);
          }
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setAlertText('Ocurrio un error, intentalo de nuevo');
        setOpen(true);
      });
    setbuttonDisabled(true);
  }

  useEffect(() => {
    if (valuesForm.card.length === 16 && valuesForm.iut.length === 12 && valuesForm.kit !== '') {
      setbuttonDisabled(true);
    } else {
      setbuttonDisabled(false);
    }
  }, [valuesForm]);

  return (
    <div>
      <Alert open={open} setOpen={setOpen} title='Mensaje del sistema' text={alertText} />
      <div className={`${styles.form} ${styles[screenSize]}`}>
        <label>Número de KIT</label>
        <div>
          <input
            type='text'
            className={styles.inputText}
            name='kit'
            value={valuesForm.kit || ''}
            onChange={(e) => changeValue(e)}
            maxLength='16'
          />
        </div>

        <label>Número de Tarjeta</label>
        <div>
          <input
            type='text'
            className={styles.inputText}
            placeholder='0000 0000 0000 0000'
            name='card'
            value={valuesForm.card || ''}
            onChange={(e) => changeValue(e)}
            maxLength='16'
          />
        </div>
        <div className={styles.errorStyle}>{msjErr.msjErrInput1}</div>
        <label>IUT</label>
        <div>
          <input
            type='text'
            className={styles.inputText}
            name='iut'
            value={valuesForm.iut || ''}
            onChange={(e) => changeValue(e)}
            maxLength='12'
          />
        </div>
        <div className={styles.errorStyle}>{msjErr.msjErrInput2}</div>
        <div className={styles.information}>
          <span className={`material-icons`} style={{ fontSize: '14px' }}>
            info
          </span>
          <label className={styles.labelInformation}>
            NOTA: Solo puedes eliminar tarjetas que previamente se hayan dado de baja
          </label>
        </div>
        <div style={{ paddingTop: '15px' }}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={(buttonDisabled && styles.button) || styles.buttonDisable}
            onClick={(e) => {
              if (buttonDisabled) handleSubmit(e);
            }}>
            {(loading && <CircularProgress />) || (
              <div className={styles.divButton}>
                Eliminar tarjeta &nbsp;
                <span className={`material-icons`}>delete</span>
              </div>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
