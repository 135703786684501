import React, { useState } from 'react';

//Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// Styles
import styles from './index.module.sass';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Alert = (props) => {
  const [alertOpen, setAlertOpen] = useState(false);

  const { open = alertOpen, setOpen = setAlertOpen, title = '', text = '', callback } = props;

  const handleClose = () => {
    if (callback) callback();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'>
      <div className={styles.container}>
        <DialogTitle id='alert-dialog-slide-title' className={styles.title}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' className={styles.text}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={styles.button} variant='contained'>
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Alert;
