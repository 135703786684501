import React from 'react';

// styles
import styles from './index.module.sass';

export default function Survey({ history, screenSize, setOptionSelected, setSectionName }) {
  setOptionSelected('survey');
  setSectionName('Encuesta clima organizacional');
  return (
    <div className={styles.aviso}>
      <div className={styles.textAviso}>El tiempo para responder ha terminado.</div>
    </div>
  );
}
