import React, { useState } from 'react';

import NewNotification from '../NewNotification';

import styles from '../index.module.sass';

const rowWidth = '16.6666%';

export default function NotificationRow({ notification, callback = () => {} }) {
  const [open, setOpen] = useState(false);
  const {
    id = '',
    fechaAlta = '',
    fechaPublicacion = '',
    titulo = '',
    descripcion = '',
    usuario = '',
    estatus = '',
  } = notification;

  return (
    <>
      <div
        className={styles.notiRow}
        style={{
          borderBottomWidth: open ? 0 : 1,
        }}>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {`${fechaAlta.substring(0, 4)}-${fechaAlta.substring(4, 6)}-${fechaAlta.substring(6, 8)}`}
        </div>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {`${fechaPublicacion.substring(0, 4)}-${fechaPublicacion.substring(
            4,
            6,
          )}-${fechaPublicacion.substring(6, 8)}`}
        </div>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {titulo}
        </div>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {usuario}
        </div>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          {estatus === 'C' ? 'Cancelado' : 'Vigente'}
        </div>
        <div
          className={`${styles.notiColumn} ${styles.centered}`}
          style={{
            width: rowWidth,
          }}>
          <i
            className='material-icons'
            onClick={() => {
              setOpen(!open);
            }}>
            edit
          </i>
        </div>
      </div>
      {open && (
        <div className={styles.postContainer}>
          <NewNotification
            idInit={id}
            statusInit={estatus}
            titleInit={titulo}
            descriInit={descripcion}
            showStatus={true}
            callback={callback}
          />
        </div>
      )}
    </>
  );
}
