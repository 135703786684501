import React, { useEffect } from 'react';
import styles from './index.module.sass';

const PaginatorList = (props) => {
  const { totalPages, page, paginator } = props;

  const showLesftRight = 4;
  const totalShow = 9;

  useEffect(() => {}, []);
  return (
    <div className={styles.paginator}>
      <div>
        {page > showLesftRight + 1 && (
          <span
            className={styles.last}
            onClick={() => {
              paginator(1);
            }}>
            Primer página
          </span>
        )}
      </div>
      <div>
        {page > showLesftRight + 1 && (
          <span
            className={styles.arrow}
            onClick={() => {
              paginator(page - 1);
            }}>
            ...
          </span>
        )}
        {totalPages.map((val, key) => {
          let k = val + 1;
          if (k <= showLesftRight && page <= showLesftRight) {
            return (
              <span
                className={k === page ? `${styles.selected} ${styles.pageNum}` : styles.pageNum}
                key={key}
                onClick={() => {
                  paginator(k);
                }}>
                página {k}
              </span>
            );
          } else if (k > showLesftRight && page <= showLesftRight && k <= totalShow) {
            return (
              <span
                className={k === page ? `${styles.selected} ${styles.pageNum}` : styles.pageNum}
                key={key}
                onClick={() => {
                  paginator(k);
                }}>
                página {k}
              </span>
            );
          } else if (page > showLesftRight) {
            if (k >= page && k <= page + showLesftRight) {
              return (
                <span
                  className={k === page ? `${styles.selected} ${styles.pageNum}` : styles.pageNum}
                  key={key}
                  onClick={() => {
                    paginator(k);
                  }}>
                  página {k}
                </span>
              );
            } else if (k <= page && k >= page - showLesftRight) {
              return (
                <span
                  className={k === page ? `${styles.selected} ${styles.pageNum}` : styles.pageNum}
                  key={key}
                  onClick={() => {
                    paginator(k);
                  }}>
                  página {k}
                </span>
              );
            }
          } else return null;

          return null;
        })}
        {page + showLesftRight < totalPages.length && (
          <span
            className={styles.arrow}
            onClick={() => {
              paginator(page + 1);
            }}>
            ...
          </span>
        )}
      </div>
      <div>
        {totalPages.length > 15 && page < totalPages.length - showLesftRight && (
          <span
            className={styles.last}
            onClick={() => {
              paginator(totalPages.length);
            }}>
            Última página {totalPages.length}
          </span>
        )}
      </div>
    </div>
  );
};

export default PaginatorList;
