const type = [
  {
    principal: 'Envíos',
    sub: [],
    mails: [
      {
        name: 'Marisol Esquivel',
        mail: 'mesquivel@terramarbrands.com',
      },
      {
        name: 'Santiago Ruiz',
        mail: 'sruiz@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
      {
        name: 'Karla Goreti Tepezano Rodríguez',
        mail: 'ktepezano@terramarbrands.com',
        kit: '9000628',
      },
    ],
    value: 1,
  },
  {
    principal: 'Producto',
    sub: ['Faltante', 'Dañado', 'Cambiado'],
    mails: [
      {
        name: 'Marisol Esquivel',
        mail: 'mesquivel@terramarbrands.com',
      },
      {
        name: 'Juan Sanchez',
        mail: 'jsanchez@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 2,
  },
  {
    principal: 'Servicio al Cliente',
    sub: [],
    mails: [
      {
        name: 'Santiago Ruiz',
        mail: 'sruiz@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 3,
  },
  {
    principal: 'Mercadotecnia',
    sub: [],
    mails: [
      {
        name: 'Adelicia Gudiño',
        mail: 'adeliciag@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 4,
  },
  {
    principal: 'Sistemas',
    sub: [],
    mails: [
      {
        name: 'Sergio Alvarez',
        mail: 'salvarezr@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 5,
  },
  {
    principal: 'Linaje',
    sub: [],
    mails: [
      {
        name: 'Alejandro Bernal',
        mail: 'linaje@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center

      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 6,
  },
  {
    principal: 'Ventas',
    sub: [],
    mails: [
      {
        name: 'Santiago Ruiz',
        mail: 'sruiz@terramarbrands.com',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
    ],
    value: 7,
  },
  {
    principal: 'Porteo',
    sub: [],
    mails: [
      {
        name: 'Marisol Esquivel',
        mail: 'mesquivel@terramarbrands.com',
        kit: '9000604',
      },
      {
        name: 'Juan Sanchez',
        mail: 'jsanchez@terramarbrands.com',
        kit: '9000605',
      },
      {
        name: 'Cintya Gonzalez',
        mail: 'cgonzalez@terramarbrands.com',
        kit: '9000049',
      },
      //Empleados de call center
      {
        name: 'Isabel Indalecio',
        mail: 'iindalecio@terramarbrands.com',
        kit: '9000644',
      },
      {
        name: 'Rogelio Hernandez',
        mail: 'rhernandez@terramarbrands.com',
        kit: '9000671',
      },
      {
        name: 'Alejandra Juarez',
        mail: 'ajuarez@terramarbrands.com',
        kit: '9000670',
      },
      {
        name: 'Luis Gutierrez',
        mail: 'lgutierrez@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Miriam Macias',
        mail: 'mmacias@terramarbrands.com',
        kit: '9000625',
      },
      {
        name: 'Elia Hermosillo',
        mail: 'ehermosillo@terramarbrands.com',
        kit: '9000528',
      },
      {
        name: 'Karla Goreti Tepezano Rodríguez',
        mail: 'ktepezano@terramarbrands.com',
        kit: '9000628',
      },
    ],
    value: 8,
  },
];

export default type;
