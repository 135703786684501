import React from 'react';
import styles from './index.module.sass';
//import superagent from 'superagent';

// function downloadItem(url, name) {
//   superagent
//     .get(url)
//     .responseType('blob')
//     .then((response) => {
//       const blob = new Blob([response.body], { type: 'text/plain' });
//       const link = document.createElement('a');
//       link.href = URL.createObjectURL(blob);
//       link.download = name;
//       link.click();
//       URL.revokeObjectURL(link.href);
//     })
//     .catch(console.error);
// }

const TableComponentPaqueteExpress = (props) => {
  const { screenSize = 'desktop', tableData = [], tableItems = [] } = props;
  const dataCellWidth = (screenSize === 'phone' && '180px') || `${100 / tableItems.length}%`;

  return (
    <div className={`${styles.tableContainer}`}>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.tableHeader}`}>
          {tableItems.map((val, key) => {
            const { title = '' } = val;
            return (
              <div className={styles.cell} style={{ width: dataCellWidth }} key={key}>
                {title}
              </div>
            );
          })}
        </div>
        {tableData.map((val, key) => {
          const columns = tableItems.map((cellVal, cellKey) => {
            const {
              field = '',
              many = false,
              isLink = false,
              useLength = false,
              isMoney = false,
              isArray = false,
            } = cellVal;
            let fieldName = '';
            if (many) {
              const tmpConst = field.split(',');
              for (let i = 0; i < tmpConst.length; i++) {
                if (i > 0) fieldName += '-';
                if (tmpConst[i] === 'mes') {
                } else {
                  fieldName += (tableData[key] && tableData[key][tmpConst[i]]) || '';
                }
              }
            } else if (useLength) {
              fieldName = tableData[key] && tableData[key][field] && tableData[key][field].length;
            } else if (isArray) {
              fieldName = tableData[key];
            } else {
              fieldName = (tableData[key] && tableData[key][field]) || '';
              if (isMoney) fieldName = `$${fieldName}`;
            }

            const cell = (
              <div className={styles.cell} style={{ width: dataCellWidth }} key={cellKey}>
                {(isLink && (
                  <a href={fieldName} className={styles.link}>
                    {fieldName}
                  </a>
                  // <div
                  //   className={styles.link}
                  //   onClick={() => downloadItem(fieldName, title)}
                  //   title='Descargar archivo'>
                  //   {fieldName}
                  // </div>
                )) ||
                  fieldName}
              </div>
            );
            return cell;
          });
          return (
            <div className={styles.row} key={key}>
              {columns}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableComponentPaqueteExpress;
