import React from 'react';

import styles from './index.module.sass';

export default function TableRow({
  screenSize,
  order = [],
  isHeader = false,
  rowNumber,
  rowSelected,
}) {
  return (
    <div
      className={`${styles.tableRow} ${styles[screenSize]} ${
        (isHeader && styles.isHeader) || null
      } ${styles[rowNumber === rowSelected && !isHeader]}`}>
      <div className={styles.rowItem}>{order?.fecha}</div>
      <div className={styles.rowItem}>{order?.noKit}</div>
      <div className={styles.rowItem}>{order?.estatus}</div>
      <div className={styles.rowItem}>{order?.productos}</div>
      <div className={styles.rowItem}>{order?.articulos}</div>
      <div className={styles.rowItem}>
        {!isHeader && '$'}
        {order?.total}
      </div>
    </div>
  );
}
