import React from 'react';
import styles from './index.module.sass';

import TableRow from './TableRow';

const Pedidos = (props) => {
  const { indicatorArray, rowSelected, setRowSelected } = props;
  const tableHeader = [
    'Año 1',
    'Mes 1',
    'Día 1',
    'Pedidos 1',
    'Ped tot 1',
    'Importe 1',
    'Total 1',
    'Año 2',
    'Mes 2',
    'Dia 2',
    'Pedidos 2',
    'Ped Tot 2',
    'Importe 2',
    'Total 2',
    'Año 3',
    'Mes 3',
    'Dia 3',
    'Pedidos 3',
    'Ped Tot 3',
    'Importe 3',
    'Total 3',
  ];

  return (
    <div className={styles.indicatorTable}>
      <div className={styles.tableHeader}>
        {tableHeader.map((val, key) => {
          return (
            <div className={styles.headerItem} key={key}>
              {val}
            </div>
          );
        })}
      </div>
      {indicatorArray.map((val, key) => {
        const order = [
          val.TINYEAR1,
          val.TINMES1,
          val.TINDIA1,
          val.TINPED1,
          val.TINPEDT1,
          (val.TINIMP1 && `$${val.TINIMP1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMP1,
          (val.TINIMPT1 && `$${val.TINIMPT1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMPT1,
          val.TINYEAR2,
          val.TINMES2,
          val.TINDIA2,
          val.TINPED2,
          val.TINPEDT2,
          (val.TINIMP2 && `$${val.TINIMP2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMP2,
          (val.TINIMPT2 && `$${val.TINIMPT2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMPT2,
          val.TINYEAR3,
          val.TINMES3,
          val.TINDIA3,
          val.TINPED3,
          val.TINPEDT3,
          (val.TINIMP3 && `$${val.TINIMP3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMP3,
          (val.TINIMPT3 && `$${val.TINIMPT3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`) ||
            val.TINIMPT3,
        ];
        return (
          <TableRow
            key={key}
            row={key}
            order={order}
            rowSelected={rowSelected}
            setRowSelected={setRowSelected}
          />
        );
      })}
    </div>
  );
};

export default Pedidos;
