import React, { useState } from "react";

//Components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

// Styles
import styles from "./index.module.sass";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Alert = (props) => {
  const [alertOpen, setAlertOpen] = useState(false);

  const {
    open = alertOpen,
    setOpen = setAlertOpen,
    title = "",
    text = "",
    callback,
  } = props;

  const handleClose = () => {
    if (callback) callback();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={styles.container}>
        <DialogTitle id="alert-dialog-slide-title" className={styles.title}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={styles.text}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={styles.button}
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Alert;
