import React, { useEffect, useCallback, useState } from 'react';

// Components
import HomeDetail from '../HomeDetail';
import HomeFixed from '../HomeFixed';
import Alert from '../Alert';
import SkeletonCmp from '../Skeleton';

// Queries
import postTerrapediaThemes from '../Queries/postTerrapediaThemes';
import postTerrapediaDepartments from '../Queries/postTerrapediaDepartments';

// Styles
import styles from './index.module.sass';

export default function Home({ history, screenSize, setOptionSelected, setSectionName }) {
  const [departments, setdepartments] = useState([]);
  const [themes, setthemes] = useState([]);
  const [themeActually, setThemeActually] = useState('');
  const [optionActually, setoptionActually] = useState('');
  const [found, setfound] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [loading, setloading] = useState(true);
  const [loadingResult, setloadingResult] = useState(true);

  async function postThemes(dpto) {
    setloadingResult(true);
    setthemes([]);
    setfound(true);
    setThemeActually(dpto);
    const themes = await postTerrapediaThemes(dpto);
    const { TypeMessage = 'error', Themes = [], Message } = themes;
    if (TypeMessage === 'success') {
      if (Themes.length > 0) {
        setthemes(Themes);
        setfound(true);
      } else {
        setfound(false);
        setAlertText('Aún no hay manuales para la opción que seleccionaste');
        setOpen(true);
      }
    } else {
      setAlertText(Message);
      setOpen(true);
    }
    setloadingResult(false);
  }

  const loadDepartmenst = useCallback(async () => {
    setloading(true);
    const res = await postTerrapediaDepartments('CONSULTAR');
    const { TypeMessage = 'error', Departments = [], Message } = res;
    if (TypeMessage === 'success') {
      setdepartments(Departments);
    } else {
      setAlertText(Message);
      setOpen(true);
    }
    setloading(false);
  }, []);

  useEffect(() => {
    loadDepartmenst();
    setOptionSelected('terrapedia');
    setSectionName('Terrapedia');
  }, [loadDepartmenst, setOptionSelected, setSectionName]);

  function postThemeFunction(val) {
    setoptionActually('');
    window.scroll(0, 0);
    postThemes(val);
  }

  function optionFunction(val) {
    setoptionActually(val);
    window.scroll(0, 0);
  }

  return (
    <div>
      <div className={`${styles[screenSize]} ${styles.container}`}>
        <Alert open={open} setOpen={setOpen} title='Terrapedia dice:' text={alertText} />

        <div className={`${styles[screenSize]} ${styles.departaments}`}>
          {!loading ? (
            <div>
              {departments.map((val, key) => {
                return (
                  <div key={key}>
                    <li className={styles.list} onClick={() => postThemeFunction(val.Department)}>
                      <label
                        className={`${themeActually === val.Department && styles.active} ${
                          styles.labelText
                        }`}>
                        {val.Department}
                      </label>
                    </li>
                  </div>
                );
              })}
            </div>
          ) : (
            <SkeletonCmp />
          )}
        </div>

        {found && (
          <div className={`${styles[screenSize]} ${styles.themesContainer}`}>
            <div className={styles.titleSelect}>&nbsp;</div>
            {!loadingResult ? (
              <div>
                {themes.map((value, key) => {
                  return (
                    <li
                      key={key}
                      className={` ${optionActually === value.Theme && styles.active} ${
                        styles.detail
                      }`}
                      onClick={() => optionFunction(value.Theme)}>
                      {value.Theme}
                    </li>
                  );
                })}
              </div>
            ) : (
              <div className={styles.skeleton}>
                <SkeletonCmp />
              </div>
            )}
          </div>
        )}

        <div className={styles.content}>
          {themeActually !== '' && optionActually !== '' && found ? (
            <HomeDetail
              themeActually={themeActually}
              optionActually={optionActually}
              screenSize={screenSize}
            />
          ) : (
            <HomeFixed screenSize={screenSize} />
          )}
        </div>
      </div>
    </div>
  );
}
