import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

const Menu = (props) => {
  const {
    match,
    device,
    open,
    setOpen,
    optionSelected,
    clearSession,
    permissions,
    Lang = {},
    setSectionName,
    setOptionSelected,
  } = props;

  const { screenSize } = device;
  const { langMenu = {} } = Lang;

  function openWindow(link) {
    setOptionSelected('users');
    setSectionName('Habilitar usuarios');
    window.open(link, '', 'target=_blank,status=no,toolbar=no,menubar=no,location=no');
  }

  return (
    <>
      {open && (
        <div className={`${styles.overlay} ${styles[open]}`} onClick={() => setOpen(false)} />
      )}
      <div className={`${styles.menuContainer} ${styles[open]} ${styles[screenSize]}`}>
        <div className={styles.header}>Terramar Brands</div>
        <div className={styles.itemsContainer}>
          <div className={styles.title}>Menú</div>
          {permissions.atenciones === '1' && (
            <Link
              to={`${match.path}/atentions/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'atentions']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>fact_check</i>
              {langMenu.atentions || ''}
            </Link>
          )}
          {permissions.indicadores === '1' && (
            <Link
              to={`${match.path}/indicators/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'indicators']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>leaderboard</i>
              {langMenu.indicators || ''}
            </Link>
          )}
          {permissions.indicadores === '1' && (
            <Link
              to={`${match.path}/digitalcatalog/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'digitalcatalog']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>feed</i>
              {langMenu.digitalcatalog || ''}
            </Link>
          )}
          {permissions.producto === '1' && (
            <Link
              to={`${match.path}/products/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'products']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>battery_full</i>
              {langMenu.products || ''}
            </Link>
          )}
          {permissions.estafeta === '1' && (
            <Link
              to={`${match.path}/estafeta/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'estafeta']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.estafeta || ''}
            </Link>
          )}
          {/* {permissions.dhl === '1' && (
            <Link
              to={`${match.path}/dhl/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'dhl']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.dhl || ''}
            </Link>
          )}
          {permissions.dhl === '1' && (
            <Link
              to={`${match.path}/dhlAvant/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'dhlAvant']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.dhlAvant || ''}
            </Link>
          )} */}
          {/* {permissions.paqueteExpress === '1' && (
            <Link
              to={`${match.path}/paqueteExpress/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'Paquete Express']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>local_shipping</i>
              {langMenu.paquete || ''}
            </Link>
          )} */}
          {permissions.loadSheet === '1' && (
            <Link
              to={`${match.path}/loadsheet/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'loadsheet']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              {langMenu.loadSheet || ''}
            </Link>
          )}
          {permissions.rop === '1' && (
            <Link
              to={`${match.path}/enableROP/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'rop']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>group</i>
              {langMenu.rop || ''}
            </Link>
          )}
          {permissions.rh === '1' && (
            <Link
              to={`${match.path}/surveyadmin/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'surveyadmin']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>apartment</i>
              {langMenu.surveyadmin || ''}
            </Link>
          )}

          {process.env.REACT_APP_COUNTRY === 'MX' && (
            <Link
              to={`${match.path}/survey/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'survey']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>mood</i>
              {langMenu.survey || ''}
            </Link>
          )}

          <Link
            to={`${match.path}/terrapedia/`}
            className={`${styles.menuItem} ${styles[optionSelected === 'terrapedia']}`}
            onClick={() => setOpen(false)}>
            <i className='material-icons'>book</i>
            {langMenu.terrapedia || ''}
          </Link>

          {/* <Link
            to={`${match.path}/activacionUsuarios/`}
            className={`${styles.menuItem} ${styles[optionSelected === 'users']}`}
            onClick={() => setOpen(false)}>
            <i className='material-icons'>person</i>
            {langMenu.users || ''}
          </Link> */}

          <Link
            to=''
            className={`${styles.menuItem} ${styles[optionSelected === 'users']}`}
            onClick={() => openWindow('http://192.168.10.24:5001/TimwarePASS/index.html')}>
            <i className='material-icons'>person</i>
            {langMenu.users || ''}
          </Link>

          <Link
            to=''
            className={`${styles.menuItem} ${styles[optionSelected === 'users']}`}
            onClick={() => openWindow('http://192.168.10.240:12331/webquery/signin')}>
            <i className='material-icons'>open_in_new</i>
            {langMenu.db2 || ''}
          </Link>

          {permissions.atenciones === '1' && (
            <Link
              to={`${match.path}/contratosUSA/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'contratos']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>description</i>
              {langMenu.contratos || ''}
            </Link>
          )}

          {permissions.notificaciones === '1' && (
            <Link
              to={`${match.path}/notifications/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'indicators']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>notifications</i>
              {langMenu.notifications || ''}
            </Link>
          )}

          {(permissions.SiVale === '1' || permissions.SiVale === '2') && (
            <Link
              to={`${match.path}/menuSiVale/`}
              className={`${styles.menuItem} ${styles[optionSelected === 'menuSiVale']}`}
              onClick={() => setOpen(false)}>
              <i className='material-icons'>credit_card</i>
              {langMenu.card || ''}
            </Link>
          )}

          <div
            className={styles.closeSession}
            onClick={() => {
              clearSession();
            }}>
            <i className='material-icons'>exit_to_app</i>
            {langMenu.logout || ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
